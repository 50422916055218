import React, { FC, useRef } from 'react'

/**
 * @param props The props object
 * @param props.imageToDisplay The image to display
 * @param props.fallbackImage The fallback image to display
 * @param props.className The class name to use
 * @param props.onClick On click handle function if there is one
 * @returns An image component with a fallback
 */
export const ImageWithFallback: FC<{
  imageToDisplay: string
  fallbackImage: string
  className?: string
  onClick?: () => void
}> = ({ imageToDisplay, fallbackImage, className, onClick }) => {
  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <img
      data-testid="imageWithFallback"
      src={imageToDisplay}
      className={className}
      ref={imageRef}
      onClick={onClick}
      onError={() => {
        if (imageRef.current) {
          imageRef.current.src = fallbackImage
        }
      }}
    />
  )
}
