import React, { FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebouncedState } from 'xund-react-utils'
import { UnifiedMedicalItem, UnifiedMedicalItemResponse } from 'medical-engine-api'

import { Col, Row } from 'antd'
import { SearchInput } from '../../General/SearchInput/SearchInput'
import { CHECK_TYPE_ILLNESS_CHECK, CHECK_TYPE_SYMPTOM_CHECK, useApiGatewayContext } from '../../../context'
import { ContentLibraryListItem } from '../../ContentLibrary/ContentLibraryListItem/ContentLibraryListItem'

import { CheckType } from '../../../models'
import { CtaButton } from '../StartScreen.styled'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../../constants'
import { useI18n, useLanguage } from '../../../hooks'
import {
  ListItemContainer,
  ResultsContainer,
  ResultType,
  StyledCol,
  StyledPagination,
} from './ContentLibrarySearch.styled'
import { isAxiosError } from 'axios'

import styles from '../../../common/Utils/PlaceholderLoadingAnimation.module.less'

const RESULTS_PER_PAGE = 5
const COMMON_ITEMS_PER_PAGE = 4

const LANGUAGES_REQUIRE_COMPACT_CTA_LAYOUT = ['fr', 'fr-formal', 'de', 'de-formal', 'hu']

/**
 * @param props The props object
 * @param props.item The UnifiedMedicalItem to show
 * @param props.onSelect The method to run clicking on the CTA button
 * @returns The ResultRow component
 */
const ResultRow: FC<{
  item: UnifiedMedicalItem
  searchText?: string | undefined
  onSelect: (item: UnifiedMedicalItem) => void
}> = ({ item, onSelect, searchText = undefined }) => {
  const { i18n } = useI18n()
  const { currentLanguage } = useLanguage()

  const isCtaLayoutCompact = LANGUAGES_REQUIRE_COMPACT_CTA_LAYOUT.includes(currentLanguage)

  const normalBreakpoints = {
    contentContainer: { xs: 24, sm: 14, md: 15, lg: 16, xl: 17 },
    buttonContainer: { xs: 24, sm: 10, md: 9, lg: 8, xl: 7 },
  }
  const compactBreakpoints = {
    contentContainer: { xs: 24, sm: 24, md: 14, lg: 12, xl: 14 },
    buttonContainer: { xs: 24, sm: 24, md: 10, lg: 12, xl: 10 },
  }

  return (
    <Row>
      <Col {...(isCtaLayoutCompact ? compactBreakpoints.contentContainer : normalBreakpoints.contentContainer)}>
        <ListItemContainer needsLessPadding={isCtaLayoutCompact}>
          <ContentLibraryListItem item={item} openableInPlace searchText={searchText} />
        </ListItemContainer>
      </Col>

      <StyledCol
        {...(isCtaLayoutCompact ? compactBreakpoints.buttonContainer : normalBreakpoints.buttonContainer)}
        needsSmallerFont={isCtaLayoutCompact}
      >
        <CtaButton type="primary" shape="round" onClick={() => onSelect(item)}>
          {item.type === 'ILLNESS' ? i18n('xund.start.startIllnessCheck') : i18n('xund.start.startSymptomCheck')}
        </CtaButton>
      </StyledCol>
    </Row>
  )
}

/**
 * @param props The props object
 * @param props.showTermsAndConditions The method for showing the terms and conditions
 * @returns The ContentLibrarySearch component
 */
export const ContentLibrarySearch: FC<{
  showTermsAndConditions: (param: CheckType) => void
}> = ({ showTermsAndConditions }) => {
  const { i18n } = useI18n()

  const [frequentContentLibraryData, setFrequentContentLibraryData] = useState<UnifiedMedicalItem[]>([])
  const [infrequentContentLibraryData, setInfrequentContentLibraryData] = useState<UnifiedMedicalItem[]>([])
  const { setValue: setSearchText, debouncedValue: searchText } = useDebouncedState(
    '',
    DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS,
  )
  const [isLoading, setIsLoading] = useState(0)
  const [error, setError] = useState<Error>()

  const [symptomsPage, setSymptomsPage] = useState(1)
  const [illnessesPage, setIllnessesPage] = useState(1)

  const { apiGateway, setInitialSymptom, setInitialIllness } = useApiGatewayContext()

  const symptomResults = useMemo(
    () => infrequentContentLibraryData.filter((result) => result.type === 'SYMPTOM'),
    [infrequentContentLibraryData],
  )
  const illnessResults = useMemo(
    () => infrequentContentLibraryData.filter((result) => result.type === 'ILLNESS'),
    [infrequentContentLibraryData],
  )

  const currentSymptomResults = useMemo(
    () => symptomResults.slice((symptomsPage - 1) * RESULTS_PER_PAGE, symptomsPage * RESULTS_PER_PAGE),
    [symptomResults, symptomsPage],
  )
  const currentIllnessResults = useMemo(
    () => illnessResults.slice((illnessesPage - 1) * RESULTS_PER_PAGE, illnessesPage * RESULTS_PER_PAGE),
    [illnessResults, illnessesPage],
  )

  const mostCommonSymptoms = useMemo(
    () => frequentContentLibraryData.filter((result) => result.type === 'SYMPTOM').slice(0, COMMON_ITEMS_PER_PAGE),
    [frequentContentLibraryData],
  )
  const mostCommonIllnesses = useMemo(
    () => frequentContentLibraryData.filter((result) => result.type === 'ILLNESS').slice(0, COMMON_ITEMS_PER_PAGE),
    [frequentContentLibraryData],
  )

  const results = [
    {
      key: 'SYMPTOMS',
      title: searchText ? i18n('xund.general.symptoms') : i18n('xund.start.commonSymptoms'),
      results: searchText && currentSymptomResults.length ? currentSymptomResults : mostCommonSymptoms,
    },
    {
      key: 'ILLNESSES',
      title: searchText ? i18n('xund.general.illnesses') : i18n('xund.start.commonIllnesses'),
      results: searchText && currentIllnessResults.length ? currentIllnessResults : mostCommonIllnesses,
    },
  ]

  const abortControllerRef = useRef<AbortController>()
  /**
   * Fetches Content Library data
   */
  const getContentLibraryData = useCallback(
    async (
      setData: (value: SetStateAction<UnifiedMedicalItem[]>) => void,
      isFrequent: boolean | 'all' = 'all',
      searchTerm?: string,
    ) => {
      try {
        setIsLoading((prevLoading) => prevLoading + 1)

        if (searchTerm && abortControllerRef.current) {
          abortControllerRef.current.abort()
        }
        abortControllerRef.current = new AbortController()
        const { data } = await apiGateway.get<UnifiedMedicalItemResponse>(`v1/contentLibrary`, {
          signal: abortControllerRef.current.signal,
          params: { searchTerm, isFrequent: isFrequent === 'all' ? undefined : isFrequent },
        })

        setData(data.items)
      } catch (err) {
        if (isAxiosError(err) && err.code !== 'ERR_CANCELED') {
          setError(err as Error)
        }
      } finally {
        setIsLoading((prevLoading) => prevLoading - 1)
      }
    },
    [apiGateway],
  )

  /**
   * Decides which data to fetch based on search term
   */
  const fetchData = useCallback(
    (searchTerm: string) => {
      if (searchTerm) {
        return getContentLibraryData(setInfrequentContentLibraryData, 'all', searchTerm)
      }

      return getContentLibraryData(setFrequentContentLibraryData, true, searchTerm)
    },
    [getContentLibraryData],
  )

  /**
   * Selects initial symptom or illness and starts a check with it
   * @param item Illness or symptom to select initially
   */
  const onSelect = (item: UnifiedMedicalItem) => {
    if (!item.code?.coding.length) {
      return
    }

    if (item.type === 'SYMPTOM') {
      setInitialSymptom(item.code.coding[0].code)
      showTermsAndConditions(CHECK_TYPE_SYMPTOM_CHECK)

      return
    }

    setInitialIllness(item.code.coding[0].code)
    showTermsAndConditions(CHECK_TYPE_ILLNESS_CHECK)
  }

  useEffect(() => {
    fetchData(searchText)
  }, [fetchData, getContentLibraryData, searchText])

  if (error) {
    throw error
  }

  return (
    <>
      <Row>
        <Col span="24">
          <SearchInput setSearchText={setSearchText} isLoading={isLoading} />
        </Col>
      </Row>

      {/* {isLoading ? <LoadingIndicator inline /> : <></>} */}

      {searchText ? (
        <>
          {results.map((resultType) => (
            <ResultsContainer
              className={isLoading > 0 ? styles.animate : {}}
              isLoading={isLoading > 0}
              key={resultType.key}
            >
              <ResultType level={5}>{resultType.title}</ResultType>

              {!resultType.results.length ? (
                <Row>
                  <Col>
                    <p>{i18n('xund.services.filters.noResults')}</p>
                  </Col>
                </Row>
              ) : (
                <>
                  {resultType.results.map((item) => (
                    <ResultRow key={item.id} item={item} onSelect={() => onSelect(item)} searchText={searchText} />
                  ))}

                  <Row justify="center">
                    <Col>
                      {resultType.key === 'ILLNESSES' && illnessResults.length > RESULTS_PER_PAGE && (
                        <StyledPagination
                          defaultCurrent={1}
                          defaultPageSize={RESULTS_PER_PAGE}
                          total={illnessResults.length}
                          onChange={(newPage) => setIllnessesPage(newPage)}
                          showSizeChanger={false}
                        />
                      )}

                      {resultType.key === 'SYMPTOMS' && symptomResults.length > RESULTS_PER_PAGE && (
                        <StyledPagination
                          defaultCurrent={1}
                          defaultPageSize={RESULTS_PER_PAGE}
                          total={symptomResults.length}
                          onChange={(newPage) => setSymptomsPage(newPage)}
                          showSizeChanger={false}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </ResultsContainer>
          ))}
        </>
      ) : (
        <>
          {results.map((resultType) => (
            <ResultsContainer key={resultType.key}>
              <ResultType level={5}>{resultType.title}</ResultType>

              {resultType.results.map((item) => (
                <ResultRow key={item.id} item={item} onSelect={() => onSelect(item)} />
              ))}
            </ResultsContainer>
          ))}
        </>
      )}
    </>
  )
}
