import React, { FC, useMemo } from 'react'
import { useQueryParams } from 'xund-react-utils'

/**
 * Authorize component for the OAuth flow
 *
 * @returns The authorize component
 */
export const Authorize: FC = () => {
  const params = useQueryParams()
  const ppInternalApiBaseUrl = window.xundEnvironment.PP_INTERNAL_API_BASE_URL

  const { clientId, state, redirectUri } = useMemo(() => {
    return {
      clientId: params.get('clientId'),
      state: params.get('state'),
      redirectUri: params.get('redirectUri'),
    }
  }, [params])

  if (!clientId || !state || !redirectUri) {
    throw new Error('Missing clientId, state, or redirectUri')
  }

  window.appLocation.replace(
    `${ppInternalApiBaseUrl}/api/v1/authorize?clientId=${clientId}&state=${state}&redirectUri=${redirectUri}`,
  )

  return <></>
}
