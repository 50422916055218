import 'antd/dist/reset.css'

import './xundTest.less'
import './App.less'
import React, { FC } from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from './components/ErrorBoundary'
import { AppRoutes } from './AppRoutes'
import inMemoryLocalStorage from './utils/inMemoryLocalStorage'
import { defaultWebAppConfig } from './resources/defaultWebAppConfig'
import { setupI18n } from './setupI18n'

try {
  window.appStorage = window.localStorage

  const entries = window.performance.getEntriesByType('navigation')

  const hotReload = entries
    .map((nav: PerformanceEntry) => {
      return (nav as PerformanceNavigationTiming).type
    })
    .includes('reload')
  if (!hotReload) {
    window.appStorage.removeItem('origin')
    window.appStorage.removeItem('token')
    window.appStorage.removeItem('token-fetching')
  }

  window.appLocation = {
    reload: () => window.location.reload(),
    replace: (url: string | URL) => window.location.replace(url),
  }
} catch (e) {
  window.appStorage = inMemoryLocalStorage

  window.appStorage.setItem('xundWebAppLanguage', defaultWebAppConfig.defaultLanguage)
  window.appLocation = {
    reload: () => {
      window.location.reload()
      window.parent.postMessage({ type: 'refresh' }, '*')
    },
    replace: (url: string | URL) => {
      window.location.replace(url)
      window.parent.postMessage({ type: 'refresh' }, '*')
    },
  }
}

setupI18n()

export const App: FC = () => {
  return (
    <ErrorBoundary>
      <AppRoutes />
    </ErrorBoundary>
  )
}

const rootElement = document.getElementById('root') || document.createElement('div')

const root = createRoot(rootElement)

root.render(<App />)
