import React, { CSSProperties, FC, useMemo } from 'react'
import { Input } from 'antd'
import { useI18n } from '../../../hooks'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './SearchInput.module.less'
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator'
import { mobileMaxWidth } from '../../../hooks'
import { useWindowSize } from 'usehooks-ts'

/**
 * @param props The props object
 * @param props.style The stylings to use
 * @param props.setSearchText The setter for the search text
 * @returns The SearchInput component
 */
export const SearchInput: FC<{
  isLoading?: number
  autoFocus?: boolean
  style?: CSSProperties
  setSearchText: (val: string) => void
}> = ({ style, setSearchText, isLoading, autoFocus }) => {
  const { i18n } = useI18n()

  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < mobileMaxWidth, [width])

  return (
    <>
      {width && (
        <Input
          className={styles.input}
          placeholder={i18n('xund.general.search')}
          autoFocus={!isMobile && autoFocus}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          style={{
            width: '100%',
            borderRadius: 5,
            fontFamily: CSS_VARS.fontFamily,
            borderWidth: 0.5,
            fontSize: 14,
            ...style,
          }}
          onChange={(e) => setSearchText(e.target.value)}
          suffix={
            isLoading ? (
              <div style={{ margin: '0 0 -3px 0' }}>
                <LoadingIndicator />
              </div>
            ) : (
              <CustomIcon icon="zoom" color={CSS_VARS.primaryColor} />
            )
          }
          size="large"
        />
      )}
    </>
  )
}
