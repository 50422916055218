import React, { FC, useState } from 'react'
import PoweredByXUNDLogoBlue from '../../../assets/images/text_logo_blue_small.png'
import { Impressum } from '../../Modals/Impressum/Impressum'
import { WaveContainer } from './PoweredByXund.styled'

/**
 * @param props The props object
 * @param props.displayWave Whether or not to display the wave in the background
 * @returns The PoweredByXund component
 */
export const PoweredByXund: FC<{
  displayWave: boolean
}> = ({ displayWave }) => {
  const [isImpressumVisible, setIsImpressumVisible] = useState(false)

  return (
    <WaveContainer displayWave={displayWave}>
      <div>
        <span>Powered by</span>
        <img src={PoweredByXUNDLogoBlue} onClick={() => setIsImpressumVisible(true)} />
      </div>

      {isImpressumVisible && <Impressum onCancel={() => setIsImpressumVisible(false)} />}
    </WaveContainer>
  )
}
