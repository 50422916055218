import { useEffect, useRef } from 'react'

/**
 * @param eventType The type of event to listen to
 * @param callback The callback to use
 * @param element The element to add the event listener onto
 */
export const useEventListener = (eventType: keyof WindowEventMap, callback: (e: Event) => void, element = window) => {
  const callbackRef = useRef<(e: Event) => void>()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    /**
     * @param e The event
     * @returns The event handler
     */
    const handler = (e: Event) => callbackRef.current?.(e)

    element.addEventListener(eventType, handler)

    return () => element.removeEventListener(eventType, handler)
  }, [eventType, element])
}
