// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d3wPMXkndOz2NlVF4oNy {\n  height: 50vh;\n  margin-bottom: 5rem;\n}\n@media screen and (max-width: 576px) {\n  .d3wPMXkndOz2NlVF4oNy {\n    height: 30vh;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/General/TokenExpiredRefresh/TokenExpiredRefresh.module.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,mBAAA;AADF;AAIA;EACE;IACE,YAAA;EAFF;AACF","sourcesContent":["@import '../../../variables.less';\n\n.droppy {\n  height: 50vh;\n  margin-bottom: 5rem;\n}\n\n@media screen and (max-width: @mobile-max-width) {\n  .droppy {\n    height: 30vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"droppy": "d3wPMXkndOz2NlVF4oNy"
};
export default ___CSS_LOADER_EXPORT___;
