import { SupportedLanguage, WebAppConfig, WebAppStyling } from '../models'

export const XUND_TERMS_AND_CONDITIONS_URL = 'https://xund.ai/terms-of-use'
export const XUND_PRIVACY_POLICY_URL = 'https://xund.ai/privacy-policy'

export const XUND_TERMS_AND_CONDITIONS_LANGUAGE_LIST: SupportedLanguage[] = ['en', 'de', 'fr', 'hu']
export const XUND_PRIVACY_POLICY_LANGUAGE_LIST: SupportedLanguage[] = ['en', 'de', 'fr', 'hu', 'nl', 'it']

export const defaultWebAppConfig: WebAppConfig = {
  id: '1',
  urlCode: '',
  webAppName: 'XUND Web App',
  browserPageTitle: 'XUND Web App',

  supportedLanguages: ['en', 'de', 'de-formal', 'fr', 'fr-formal', 'hu', 'nl', 'nl-formal'],
  defaultLanguage: 'en',

  favicon: '',
  backgroundImage: '',
  boxShadowHidden: false,
  navBarLogo:
    'https://cms.class2.xund.solutions/api/public/image/1693324149961-c3ab1419-268f-4539-a214-cd445b5ab31b.png',
  mainLogo: 'https://cms.class2.xund.solutions/api/public/image/1692374918805-71289e08-65c0-4221-8799-e670ecf1a3a6.png',
  /* cSpell:disable */
  welcomeGreeting: {
    en: 'Welcome to your personal health assistant',
    de: 'Willkommen zu deinem persönlichen Gesundheitsassistenten',
    hu: 'Üdvözlünk a személyes egészségügyi asszisztensednél',
    'de-formal': 'Willkommen zu Ihrem persönlichen Gesundheitsassistenten',
    fr: 'Bienvenue à ton assistant personnel de santé',
    'fr-formal': 'Bienvenue à votre assistant personnel de santé',
    nl: 'Welkom bij je persoonlijke gezondheidsassistent',
    'nl-formal': 'Welkom bij uw persoonlijke gezondheids-assistent',
  },
  welcomeMessage: {
    en: 'Our Patient Interaction Suite enables you to understand your symptoms, check the risk of suffering from a specific medical condition, get an overview about your personal risk factors, and will navigate you to the right point of care.',
    de: 'Unsere Patient Interaction Suite ermöglicht es dir, deine Symptome zu verstehen, das Risiko für eine bestimmte Krankheit zu überprüfen, einen Überblick über deine persönlichen Risikofaktoren zu erhalten und dich zur richtigen Anlaufstelle zu leiten.',
    hu: 'A Patient Interaction Suite segít abban, hogy megértsd tüneteidet, ellenőrizd egy adott betegség kockázatát, áttekintést kapjál személyes kockázati tényezőidről és a megfelelő ellátási ponthoz juss.',
    'de-formal':
      'Unsere Patient Interaction Suite ermöglicht es Ihnen, Ihre Symptome zu verstehen, das Risiko für eine bestimmte Krankheit zu überprüfen, einen Überblick über Ihre persönlichen Risikofaktoren zu erhalten und Sie zur richtigen Anlaufstelle zu leiten.',
    fr: 'Notre Patient Interaction Suite te permet de comprendre tes symptômes, de vérifier le risque de souffrir d’une pathologie spécifique ou d’avoir une vue d’ensemble de tes facteurs de risque personnels.',
    'fr-formal':
      'Notre Patient Interaction Suite vous permet de comprendre vos symptômes, de vérifier le risque de souffrir d’une pathologie spécifique ou d’avoir une vue d’ensemble de vos facteurs de risque personnels.',
    nl: 'Onze Patient Interaction Suite zorgt ervoor dat je je symptomen kan begrijpen, je risico op een bepaalde ziekte kan checken, een overzicht krijgt van je persoonlijke risicofactoren en navigeert je naar het juiste zorgpunt.',
    'nl-formal':
      'Onze Patient Interaction Suite zorgt ervoor dat u uw symptomen kan begrijpen, uw risico op een bepaalde ziekte kan checken, een overzicht krijgt van uw persoonlijke risicofactoren en navigeert u naar het juiste zorgpunt.',
  },
  endCheckMessage: {
    en: 'The check is complete. Have a nice day!',
    de: 'Die Überprüfung ist abgeschlossen. Einen schönen Tag noch!',
    hu: 'Az ellenőrzést befejeztük. További szép napot!',
    'de-formal': 'Die Überprüfung ist abgeschlossen. Einen schönen Tag noch!',
    fr: 'La vérification est terminée. Je te souhaite une bonne journée !',
    'fr-formal': 'La vérification est terminée. Je vous souhaite une bonne journée !',
    nl: 'De verificatie is voltooid. Ik wens je een fijne dag!',
    'nl-formal': 'De verificatie is voltooid. Ik wens u een fijne dag!',
  },
  termsAndConditionsExplanatoryText: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea {generalTerms} an {dataPrivacy} commodo consequat. \n<br><br>\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."\n<br><br>\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea {generalTerms} an {dataPrivacy} commodo consequat. \n<br><br>\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."\n<br><br>\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    hu: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea {generalTerms} an {dataPrivacy} commodo consequat. \n<br><br>\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."\n<br><br>\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    'de-formal':
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea {generalTerms} an {dataPrivacy} commodo consequat. \n<br><br>\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."\n<br><br>\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    fr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea {generalTerms} an {dataPrivacy} commodo consequat. \n<br><br>\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."\n<br><br>\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    'fr-formal':
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea {generalTerms} an {dataPrivacy} commodo consequat. \n<br><br>\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."\n<br><br>\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    nl: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea {generalTerms} an {dataPrivacy} commodo consequat. \n<br><br>\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."\n<br><br>\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    'nl-formal':
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea {generalTerms} an {dataPrivacy} commodo consequat. \n<br><br>\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."\n<br><br>\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
  },
  /* cSpell:enable */

  termsAndConditionsUrl: XUND_TERMS_AND_CONDITIONS_URL,
  privacyPolicyUrl: XUND_PRIVACY_POLICY_URL,

  apiKey: {
    scopes: [],
  },

  skipLegalQuestions: false,

  scReportPatientIntent: false,
}

export const defaultWebAppStyling: WebAppStyling = {
  primaryColor: '#0065bd',
  textColor: '#000000',
  successColor: '#0065bd',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  buttonWeight: '400',
  buttonSize: 'middle',

  navBarBackgroundColor: '#ffffff',
  navBarHamburgerIconColor: '#0065bd',

  sideMenuCloseIconColor: '#ffffff',
  sideMenuFontColor: '#ffffff',
  chatBackgroundColor: '#ffffff',

  questionFontColor: '#00417a',
  questionFontSize: '20px',

  outlinedButtonBackgroundColor: '#ffffff',
  outlinedButtonBorderColor: '#d9d9d9',
  outlinedButtonFontColor: '#000000',

  filledButtonFontColor: '#ffffff',

  skipButtonBackgroundColor: '#ffffff',
  skipButtonBorderColor: '#0065BD',
  skipButtonFontColor: '#0065BD',

  confirmButtonFontColor: '#ffffff',

  modalTitleFontSize: '20px',

  inactiveElementColor: '#dbdbdb',

  bodyPartSelectorSVGBodyOutlineAndHoverColor: '#cccccc',
  bodyPartSelectorSVGColor: '#FFFFFF',

  reportProgressBackgroundColor: '#E5EDF4',

  progressBarActiveStepColor: '#00417a',
  progressBarInactiveStepColor: '#808080',
  progressBarProgressColor: '#0065BD',
  progressBarProgressBackgroundColor: '#f2f2f2',

  fontFamily:
    "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Avenir Next', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontCssUrl: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
}
