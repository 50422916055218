import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ConfigProvider } from 'antd'
import { ErrorResponseType } from './components/ErrorBoundary'
import { LoadingIndicator } from './common/Indicators/LoadingIndicator'
import { PageNotFound } from './components/PageNotFound/PageNotFound'
import { ApiGatewayContext, AppStateContext, ContinueCheckStatus, Gender, WebAppConfigContext } from './context'
import { useApiGateway, useLanguage, useWebAppConfigLoader } from './hooks'
import { useAuthToken } from './hooks/useAuthToken'
import {
  CheckType,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_ID,
  DATADOG_SERVICE_NAME,
  DATADOG_SITE,
  MATOMO_ACTIVE_ENVIRONMENT,
  MATOMO_SITE_ID_MAP,
  MATOMO_URL,
  PatientIntentType,
  ResponseType,
  TranslationCodeLiteral,
} from './models'
import { QuestionContext } from './context/QuestionContext'
import { startQuestion } from './resources/startingQuestion'
import { CSS_VARS } from './resources/cssVariableConfig'
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react'
import { isDatadogEnabled, isMatomoEnabled } from './utils/envHelpers'
import { datadogRum } from '@datadog/browser-rum'
import { useApiCHExternal } from './hooks/useApiCHExternal'

/**
 * A loader to load and provide data needed for the app
 *
 * @param props The props object
 * @param props.children The children object
 * @returns A wrapper with the required contexts
 */
export const LoaderLayer: FC<{
  children: ReactNode
}> = ({ children }) => {
  const { token, origin } = useAuthToken()
  const { webAppConfig, isWebAppConfigLoading, webAppConfigError, isConfiguredWebapp } = useWebAppConfigLoader(token)
  const {
    apiGateway,
    medicalServices,
    checkId,
    setCheckId,
    initialSymptom,
    setInitialSymptom,
    initialIllness,
    setInitialIllness,
  } = useApiGateway(token)

  const { apiCHExternal } = useApiCHExternal(token)

  const { currentLanguage, changeLanguage } = useLanguage()

  const [originIsValid, setOriginIsValid] = useState<boolean | null>(null)

  const [hideSkipButton, setHideSkipButton] = useState<boolean>(false)
  const [currentResponse, setCurrentResponse] = useState<ResponseType | null>(startQuestion)
  const [nextButtonLogic, setNextButtonLogic] = useState<(() => void) | null>(null)
  const [nextButtonI18nKey, setNextButtonI18nKey] = useState<TranslationCodeLiteral | ''>('xund.general.confirm')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false)
  const [isReporting, setIsReporting] = useState<boolean>(false)
  const [isCheckFinished, setIsCheckFinished] = useState<boolean>(false)
  const [checkType, setCheckType] = useState<CheckType>('')
  const [mainContainerWidth, setMainContainerWidth] = useState<number>(0)
  const [isServicesOpen, setServicesOpen] = useState(false)
  const [isSideMenuOpen, setSideMenuOpen] = useState(false)
  const [isContentLibraryOpen, setContentLibraryOpen] = useState(false)
  const [initialGender, setInitialGender] = useState<Gender | ''>('')
  const [initialBirth, setInitialBirth] = useState('')
  const [initialHeight, setInitialHeight] = useState<number | undefined>(undefined)
  const [initialWeight, setInitialWeight] = useState<number | undefined>(undefined)
  const [initialAge, setInitialAge] = useState<number | undefined>(undefined)
  const [continueCheckStatus, setContinueCheckStatus] = useState<ContinueCheckStatus | ''>('')
  const [isCheckOnlyMode, setCheckOnlyMode] = useState(false)
  const [isRestartDisabled, setRestartDisabled] = useState(false)
  const [isPreventNavigationHookEnabled, setPreventNavigationHookEnabled] = useState(true)

  const [patientIntent, setPatientIntent] = useState<PatientIntentType | null>(null)

  const check = useRef(crypto.getRandomValues(new Uint32Array(40)).join(''))

  useEffect(() => {
    if (!window.appStorage.getItem('xundWebAppLanguage') && !isWebAppConfigLoading) {
      changeLanguage(webAppConfig.defaultLanguage)
      window.appLocation.reload()
    }
  }, [isWebAppConfigLoading, webAppConfig, changeLanguage, currentLanguage])

  const isBaseLoading = useMemo(() => isWebAppConfigLoading, [isWebAppConfigLoading])

  const isPageNotFoundError = useMemo(
    () => (webAppConfigError as ErrorResponseType)?.response?.data?.customType === 'NOT_FOUND_ERROR',
    [webAppConfigError],
  )

  const matomoSiteId = useMemo(() => {
    const webappType = isConfiguredWebapp ? 'client' : 'internal'
    const environment = window.xundEnvironment.EXEC_ENV
    if (!MATOMO_ACTIVE_ENVIRONMENT.includes(environment)) {
      return 999
    }

    return MATOMO_SITE_ID_MAP[environment][webappType]
  }, [isConfiguredWebapp])

  const matomoInstance = createInstance({
    urlBase: MATOMO_URL,
    siteId: matomoSiteId,
    disabled: !isMatomoEnabled() || !matomoSiteId,
    linkTracking: false,
    configurations: {
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST',
    },
  })

  const onResponse = useCallback(
    (event: MessageEvent) => {
      if (!event.data) return

      if (event.data.check && event.origin === origin) {
        if (event.data.check === check.current) {
          setOriginIsValid(true)
          window.removeEventListener('message', onResponse)
        } else {
          throw Error(`Origin does not match.`)
        }
      }
    },
    [origin],
  )

  const sendOriginProof = useCallback(() => {
    if (origin) {
      window.parent.postMessage({ check: check.current }, origin ?? '')
    }
  }, [origin])

  const originCheckInitialized = useRef(false)

  useEffect(() => {
    if (!originCheckInitialized.current && token) {
      if (origin) {
        sendOriginProof()
      } else {
        setOriginIsValid(true)
      }
      originCheckInitialized.current = true
      window.addEventListener('message', onResponse, false)
    }
  }, [onResponse, origin, sendOriginProof, token])

  useEffect(() => {
    if (isDatadogEnabled()) {
      datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_ID,
        site: DATADOG_SITE,
        service: DATADOG_SERVICE_NAME,
        env: window.xundEnvironment.EXEC_ENV.toLowerCase(),
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: false,
        trackResources: false,
        trackLongTasks: false,
        defaultPrivacyLevel: 'mask-user-input',
      })
    }
  }, [])

  if (isBaseLoading) {
    return (
      <div style={{ height: '100%' }}>
        <LoadingIndicator delay={50} />
      </div>
    )
  }

  if (continueCheckStatus === 'INVALID_ID') {
    throw Error(`The checkID or API key is incorrect`)
  }

  return (
    <>
      {originIsValid === false ? (
        <div>Origin does not match.</div>
      ) : (
        <ApiGatewayContext.Provider
          value={{
            apiGateway,
            medicalServices,
            checkId,
            setCheckId,
            initialSymptom,
            setInitialSymptom,
            initialIllness,
            setInitialIllness,
          }}
        >
          <WebAppConfigContext.Provider
            value={{
              webAppConfig,
              isWebAppConfigLoading,
              webAppConfigError,
              apiCHExternal,
              patientIntent,
              setPatientIntent,
            }}
          >
            <AppStateContext.Provider
              value={{
                isServicesOpen,
                setServicesOpen,
                isSideMenuOpen,
                setSideMenuOpen,
                isContentLibraryOpen,
                setContentLibraryOpen,
                initialGender,
                setInitialGender,
                initialBirth,
                setInitialBirth,
                initialHeight,
                setInitialHeight,
                initialWeight,
                setInitialWeight,
                continueCheckStatus,
                setContinueCheckStatus,
                isCheckOnlyMode,
                setCheckOnlyMode,
                isRestartDisabled,
                setRestartDisabled,
                initialAge,
                setInitialAge,
                isPreventNavigationHookEnabled,
                setPreventNavigationHookEnabled,
              }}
            >
              <QuestionContext.Provider
                value={{
                  hideSkipButton,
                  setHideSkipButton,
                  currentResponse,
                  setCurrentResponse,
                  nextButtonLogic,
                  setNextButtonLogic,
                  nextButtonI18nKey,
                  setNextButtonI18nKey,
                  isLoading,
                  setIsLoading,
                  isNextButtonDisabled,
                  setIsNextButtonDisabled,
                  isReporting,
                  setIsReporting,
                  isCheckFinished,
                  setIsCheckFinished,
                  checkType,
                  setCheckType,
                  mainContainerWidth,
                  setMainContainerWidth,
                }}
              >
                <ConfigProvider
                  theme={{
                    components: {
                      Input: {
                        colorTextPlaceholder: CSS_VARS.DARK_GREY_COLOR,
                      },
                    },
                    token: {
                      screenSM: 577,
                      screenSMMin: 577,
                      screenXSMax: 576,
                    },
                  }}
                >
                  {/*Necessary because of old 3rd party library that misses the children prop*/}
                  {/*eslint-disable-next-line*/}
                  {/*@ts-ignore*/}
                  <MatomoProvider value={matomoInstance}>
                    {isPageNotFoundError ? <PageNotFound /> : children}
                  </MatomoProvider>
                </ConfigProvider>
              </QuestionContext.Provider>
            </AppStateContext.Provider>
          </WebAppConfigContext.Provider>
        </ApiGatewayContext.Provider>
      )}
    </>
  )
  // return (
  // )
}
