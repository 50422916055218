import { Question } from '../models/Question'

export const startQuestion: Question = {
  detailedType: 'START_SCREEN',
  id: '',
  text: '',
  type: 'SINGLE_SELECT',
  isSkippable: false,
  isAnswered: false,
  checkStateInfo: { state: 'START', detailedState: 'INITIAL' },
}

export const legalDisclaimer: Question = {
  detailedType: 'TERMS_AND_CONDITIONS',
  id: '',
  text: '',
  type: 'SINGLE_SELECT',
  isSkippable: false,
  isAnswered: false,
  checkStateInfo: { state: 'START', detailedState: 'INITIAL' },
}

export const loading: Question = {
  detailedType: 'LOADING',
  id: '',
  text: '',
  type: 'SINGLE_SELECT',
  isSkippable: false,
  isAnswered: false,
  checkStateInfo: { state: 'START', detailedState: 'INITIAL' },
}
