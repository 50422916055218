import styled from '@emotion/styled'
import { CSS_VARS, JS_STYLING } from '../../resources/cssVariableConfig'
import { Typography } from 'antd'

export const StyledHeader = styled.header`
  max-height: 70px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 60px;
  box-sizing: border-box;
  z-index: 999;
  background: ${CSS_VARS.navBarBackgroundColor};
  @media (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    padding: 25px 20px;
  }
`

export const ProgressBar = styled.div<{ progressPercent: number }>`
  background-color: ${CSS_VARS.progressBarProgressBackgroundColor};
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-left: ${(props) => `${props.progressPercent}vw solid ${CSS_VARS.progressBarProgressColor};`}
  box-sizing: border-box;
  transition: border-width 0.5s;
`

export const Logo = styled.div`
  height: 22px;
  cursor: pointer;
  img {
    max-height: 100%;
    width: auto;
    height: 22px;
  }
`

export const StatusRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StatusLabel = styled(Typography.Text)<{ isactive: string }>`
  color: ${(props) =>
    props.isactive === 'true' ? CSS_VARS.progressBarActiveStepColor : CSS_VARS.progressBarInactiveStepColor};
  font-family: ${CSS_VARS.fontFamily};
  margin-right: 50px;
  :first-of-type {
    margin-left: 50px;
  }
`
