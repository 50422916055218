import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Card, Text } from '../xund-ui-legacy-components'
import { CSS_VARS, JS_STYLING } from '../../resources/cssVariableConfig'
import { CustomIcon } from '../CustomIcon/CustomIcon'

export const Container = styled.div`
  border-radius: 10px;
  padding-bottom: 30px;
`

export const TransparencyRemoverContainer = styled.div`
  svg {
    path {
      fill-opacity: 1;
    }
  }
`

const rowCommon = css`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  gap: 10px;
`

export const QuickSolutionsRow = styled.div`
  ${rowCommon}
`

export const SpecializationsRow = styled.div`
  ${rowCommon}

  display: flex;
  align-items: center;
  margin-top: 10px;

  @media screen and (orientation: portrait) and (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}) {
    margin: 5% auto 0;
  }

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) {
    margin: 7% auto 0;
  }
`

const itemCommon = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  line-height: 1.2;
  min-height: 80px;
  min-width: 120px;

  span {
    font-size: 12px;
  }
`

const itemPortraitCommon = css`
  min-height: 60px;
  min-width: 80px;
  line-height: 1;
  padding: 10px 0;
`

const itemLandscapeCommon = css`
  width: 120px;
  height: 40px;
  padding: 20px 0;
`

const itemMobileCommon = css`
  min-height: 40px;
  min-width: 60px;
  line-height: 1;
  padding: 15px 0;

  span {
    font-size: 10px;
  }
`

export const QuickSolutionsItem = styled(Card)`
  ${itemCommon}

  @media screen and (orientation: portrait) and (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}) {
    ${itemPortraitCommon}
  }

  @media screen and (orientation: landscape) {
    ${itemLandscapeCommon}
  }

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) {
    ${itemMobileCommon}
  }
`

const iconCommon = css`
  width: 20px;
  height: 20px;
`

export const QuickSolutionsIcon = styled(CustomIcon)`
  ${iconCommon}

  @media screen and (orientation: portrait) and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    height: 20px;
  }

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) {
    width: 25px;
    height: 25px;
  }
`

export const QuickSolutionsText = styled(Text)`
  color: ${CSS_VARS.questionFontColor};
  padding-left: 1px;
  padding-right: 1px;
  margin: 5px 0 0 0;
  font-size: 12px;
  font-family: 'Avenir Next';
  font-weight: 400;
`

export const SpecializationsItem = styled(Card)`
  ${itemCommon}

  @media screen and (orientation: portrait) and (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}) {
    ${itemPortraitCommon}
  }

  @media screen and (orientation: landscape) {
    ${itemLandscapeCommon}
  }

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) and (max-height: ${JS_STYLING.MOBILE_MAX_HEIGHT}) {
    ${itemMobileCommon}
  }
`

export const SpecializationsIcon = styled(CustomIcon)`
  ${iconCommon}

  fill: ${CSS_VARS.chatBackgroundColor};
  color: ${CSS_VARS.questionFontColor};
`

export const SpecializationsText = styled(Text)`
  color: ${CSS_VARS.questionFontColor};
  margin: 5px 0 0 0;
  font-size: 12px;
  font-family: 'Avenir Next';
  font-weight: 400;
`
