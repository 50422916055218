import { useState } from 'react'
import { useEventListener } from './useEventListener'

/**
 * @returns The boolean whether the user is currently online
 */
export const useIsUserOnline = () => {
  const [isUserOnline, setIsUserOnline] = useState(navigator.onLine)

  useEventListener('online', () => setIsUserOnline(navigator.onLine))
  useEventListener('offline', () => setIsUserOnline(navigator.onLine))

  return { isUserOnline }
}
