import { useEffect, useRef } from 'react'

/**
 * @param value A current state value
 * @returns The previous state value for a given state
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
