import { BBox } from '@turf/helpers'
import { DayFilterKeys } from '../models/servicesFilters'

export const DEFAULT_SERVICES_MAP_DEBOUNCE_TIME_IN_MS = 800

export const DAYS_OF_THE_WEEK = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as DayFilterKeys[]

export const NAME_OF_TODAYS_DAY = new Intl.DateTimeFormat('en-US', { weekday: 'short' })
  .format(new Date())
  .toLowerCase()

export const CURRENT_HOUR = new Date().getHours()

export const DEFAULT_COUNTRY = 'AT'
export const COUNTRY_CODES_SUPPORTED_BY_SERVICES = ['AT', 'DE']

export const DEFAULT_MAPBOX_RADIUS = 75
export const DEFAULT_MAPBOX_ZOOM_LEVEL = 14

export const MAPBOX_MAX_ZOOM_LEVEL_OFFSET = 3
export const MAPBOX_MAX_ZOOM_LEVEL = DEFAULT_MAPBOX_ZOOM_LEVEL + MAPBOX_MAX_ZOOM_LEVEL_OFFSET

export const DEFAULT_LONGITUDE = 16.3579486
export const DEFAULT_LATITUDE = 48.1977362

export const DEFAULT_BOUNDS_PRIMARY_LONGITUDE = 16.334559737389583
export const DEFAULT_BOUNDS_PRIMARY_LATITUDE = 48.19014081456589

export const DEFAULT_BOUNDS_SECONDARY_LONGITUDE = 16.381337462609338
export const DEFAULT_BOUNDS_SECONDARY_LATITUDE = 48.20533045955753

export const DEFAULT_BOUNDS = [
  DEFAULT_BOUNDS_PRIMARY_LONGITUDE,
  DEFAULT_BOUNDS_PRIMARY_LATITUDE,
  DEFAULT_BOUNDS_SECONDARY_LONGITUDE,
  DEFAULT_BOUNDS_SECONDARY_LATITUDE,
] as BBox

export const MAPBOX_MAP_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places'
