import React, { ComponentPropsWithoutRef, ElementRef, ElementType, forwardRef } from 'react'
import { Theme } from '@emotion/react'

import { StyledCard } from './Card.styled'

export interface CardProps {
  radius?: string
  shadow?: number
  as?: ElementType
}

const DEFAULT_CARD_TAG: ElementType = 'div'

const theme = {
  colors: {
    background: '#FFFFFF',
  },
  radius: {
    flat: '0px',
    sm: '3px',
    md: '5px',
    lg: '10px',
    xl: '25px',
    circular: '99999px',
  },
  shadow: [
    '0px 2px 7px rgba(0, 65, 122, 0.14)',
    '0px 10px 20px rgba(0, 65, 122, 0.08)',
    '0px 12px 35px rgba(0, 65, 122, 0.08)',
    '0px 14px 50px rgba(0, 65, 122, 0.08)',
  ],
}

export const Card = forwardRef<
  ElementRef<typeof DEFAULT_CARD_TAG>,
  CardProps & ComponentPropsWithoutRef<typeof DEFAULT_CARD_TAG>
>(({ children, as, radius, shadow, ...props }, ref) => {
  return (
    <StyledCard
      ref={ref}
      style={{
        backgroundColor: theme.colors.background,
        display: 'block',
        borderRadius: theme.radius[(radius ?? 'lg') as keyof Theme],
        boxShadow: theme.shadow[shadow ?? 2],
        WebkitBoxShadow: theme.shadow[shadow ?? 2],
        MozBoxShadow: theme.shadow[shadow ?? 2],
      }}
      {...props}
    >
      {children}
    </StyledCard>
  )
})
