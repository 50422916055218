import { createContext, useContext } from 'react'
import { DEFAULT_COUNTRY } from '../constants'
import { ServiceWithDetailedOpeningHours } from '../models'
import { GenericSearchParameters } from '../models/GenericSearchParameters'
import { ListResponse } from '../models/ListResponse'

const initialValue: {
  getMatchingFiltersServicesData: (searchTerm?: string, filters?: GenericSearchParameters) => void
  matchingFiltersServicesData: ListResponse<ServiceWithDetailedOpeningHours>
  notMatchingFiltersServicesData: ListResponse<ServiceWithDetailedOpeningHours>
  selectedService: ServiceWithDetailedOpeningHours | null
  setSelectedService: (val: ServiceWithDetailedOpeningHours | null) => void
  filterObject: GenericSearchParameters
  setFilterObject: (val: GenericSearchParameters) => void
  requestFilter: GenericSearchParameters
  setRequestFilter: (val: GenericSearchParameters) => void
  searchText: string
  setSearchText: (val: string) => void
  coordinates: { longitude: number; latitude: number }
  setCoordinates: (val: { longitude: number; latitude: number }) => void
  country: string
  setCountry: (val: string) => void
  lastSupportedCountryVisited: string
  isSupportedCountry: boolean
  setCountryCodeFromCurrentCoordinates: (val: { longitude: number; latitude: number }) => void
  isMatchingFiltersServicesLoading: boolean
  isNotMatchingFiltersServicesLoading: boolean
  setMapGLSupported: (val: boolean) => void
  mapGLSupported?: boolean
  error: Error | null
} = {
  getMatchingFiltersServicesData: () => '',
  matchingFiltersServicesData: { count: 0, items: [] },
  notMatchingFiltersServicesData: { count: 0, items: [] },
  selectedService: null,
  setSelectedService: () => '',
  filterObject: {},
  setFilterObject: () => null,
  requestFilter: {},
  setRequestFilter: () => null,
  searchText: '',
  setSearchText: () => '',
  coordinates: { longitude: 0, latitude: 0 },
  setCoordinates: () => '',
  country: DEFAULT_COUNTRY,
  setCountry: () => '',
  lastSupportedCountryVisited: DEFAULT_COUNTRY,
  setCountryCodeFromCurrentCoordinates: () => '',
  isSupportedCountry: true,
  isMatchingFiltersServicesLoading: false,
  isNotMatchingFiltersServicesLoading: false,
  setMapGLSupported: (val: boolean) => null,
  mapGLSupported: true,
  error: null,
}

export const ServicesContext = createContext(initialValue)

/**
 * The custom hook for the Localization context
 *
 * @returns The custom hook for the Localization context
 */
export const useServicesContext = () => useContext(ServicesContext)
