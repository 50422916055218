import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedState } from 'xund-react-utils'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS, MAPBOX_MAP_BASE_URL } from '../constants'
import { useServicesContext } from '../context'
import { AutocompleteResult } from '../models'
import { useLanguage } from './'

/**
 * @returns The Mapbox autocomplete hook
 */
export const useMapboxAutocomplete = () => {
  const [results, setResults] = useState<AutocompleteResult[]>([])
  const {
    setValue: setSearchText,
    debouncedValue: searchText,
    value: searchValue,
  } = useDebouncedState('', DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS)
  const { country } = useServicesContext()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const { currentLanguage } = useLanguage()

  /**
   * @param searchTerm The search term to use
   */
  const getResults = useCallback(
    async (searchTerm: string) => {
      setIsLoading(true)

      const languageCodeLength = 2
      const validLanguageCode = currentLanguage.slice(0, languageCodeLength)

      try {
        const { data } = await axios(`${MAPBOX_MAP_BASE_URL}/${searchTerm}.json`, {
          params: {
            access_token: window.xundEnvironment.MAPBOX_ACCESS_TOKEN,
            autocomplete: true,
            limit: 10,
            language: validLanguageCode,
            country,
          },
        })

        setResults(data?.features)
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsLoading(false)
      }
    },
    [currentLanguage, country],
  )

  useEffect(() => {
    getResults(searchText)
  }, [getResults, searchText])

  return {
    searchValue,
    setSearchText,
    results,
    isLoading,
    error,
  }
}
