import styled from '@emotion/styled'
import { Col } from 'antd'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(128, 128, 128, 0.2);
  cursor: pointer;

  svg {
    width: 10px;
    fill: ${CSS_VARS.PRIMARY_COLOR} !important;
    stroke: ${CSS_VARS.PRIMARY_COLOR} !important;
  }
`

export const LocationContainer = styled.div`
  margin-top: 24px;
`

export const StyledCol = styled(Col)`
  margin-left: 30px;
`
