import styled from '@emotion/styled'
import { CSS_VARS } from '../../resources/cssVariableConfig'

export const Text = styled.span`
  z-index: 1;
  position: relative;
`

export const Mark = styled.span`
  padding: 0 0.1em;
  margin: 0 -0.1em;
  background-color: ${CSS_VARS.reportProgressBackgroundColor};
`
