// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QHnB9xKV8C3V_GI4_pFX input.ant-input {\n  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Avenir Next', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;\n}\n@media screen and (max-width: 576px) {\n  input.ant-input {\n    font-size: 16px!important;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/General/SearchInput/SearchInput.module.less"],"names":[],"mappings":"AAAA;EAGY,uMAAA;AADZ;AASA;EACI;IAEQ,yBAAA;EARV;AACF","sourcesContent":[".input {\n    :global {\n        input.ant-input {\n            font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Avenir Next', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',\n    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;\n        }\n\n    }\n}\n\n// avoid zoom when focusing inputs on iphones:\n@media screen and (max-width: 576px) {\n    :global {\n        input.ant-input {\n            font-size: 16px!important;\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "QHnB9xKV8C3V_GI4_pFX"
};
export default ___CSS_LOADER_EXPORT___;
