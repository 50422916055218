import axios from 'axios'
import { useEffect, useMemo } from 'react'
import { useLanguage } from './useLanguage'
import { useWebAppConfigContext } from '../context'

/**
 * The API Gateway axios instance factory
 *
 * @param token The token to be used for the API Gateway
 * @returns The axios instance to be used for the API Gateway
 */
export const useApiCHExternal = (token: string | null, checkId?: string) => {
  const { currentLanguage } = useLanguage()

  const {
    webAppConfig: { urlCode },
  } = useWebAppConfigContext()

  /**
   * @returns An axios instance
   */
  const apiCHExternal = useMemo(() => {
    return axios.create({
      baseURL: window.xundEnvironment.CH_EXTERNAL_API,
      timeout: 60000,
    })
  }, [])

  useEffect(() => {
    const interceptorId = apiCHExternal.interceptors.request.use(
      async (config) => {
        config.headers.setAuthorization(`Bearer ${token}`)
        config.headers.set('language', currentLanguage)
        config.headers.set('check-id', checkId)
        config.headers.set('code', urlCode)
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    return () => {
      apiCHExternal.interceptors.request.eject(interceptorId)
    }
  }, [apiCHExternal.interceptors.request, token, checkId, currentLanguage, urlCode])

  return {
    apiCHExternal,
    checkId,
    urlCode,
  }
}
