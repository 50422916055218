import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ErrorResponseType } from '../components/ErrorBoundary'
import { SupportedLanguage, WebAppConfig, WebAppStyling } from '../models'
import { appendCustomizedStylesToDOM } from '../resources/customizedAntDesignStylings'
import { defaultWebAppConfig, defaultWebAppStyling } from '../resources/defaultWebAppConfig'
import initCssVariableConfig, { JS_STYLING } from '../resources/cssVariableConfig'
import { useBusinessAdmin } from './useBusinessAdmin'
import axios, { AxiosError } from 'axios'

const defaultConfig = {
  ...defaultWebAppConfig,
  welcomeGreeting: {},
  welcomeMessage: {},
  endCheckMessage: {},
  termsAndConditionsExplanatoryText: {},
}

export const useWebAppConfigLoader = (token: string | null) => {
  const [webAppConfig, setWebAppConfig] = useState<WebAppConfig>(defaultConfig)
  const [isWebAppConfigLoading, setIsWebAppConfigLoading] = useState(true)
  const [webAppConfigError, setWebAppConfigError] = useState<Error | ErrorResponseType | null>(null)

  const { code } = useParams<{ code: string }>()

  const { businessAdmin } = useBusinessAdmin(token)
  const isMedicalResearchMode = window.xundEnvironment.APP_MODE === 'MEDICAL_RESEARCH'

  const isConfiguredWebapp = !!code || isMedicalResearchMode

  const getWebAppConfig = useCallback(async () => {
    const urlCode = code || window.xundEnvironment.WEBAPP_CODE_DEFAULT

    let config: WebAppConfig = defaultConfig
    let styling: WebAppStyling = defaultWebAppStyling
    window.parent.postMessage({ title: config.browserPageTitle, favicon: config.favicon }, '*')

    const currentLanguage: SupportedLanguage | null = window.appStorage.getItem(
      'xundWebAppLanguage',
    ) as SupportedLanguage

    try {
      if (!token) {
        return
      }
      if (urlCode?.length) {
        const response = await businessAdmin.get(`/webAppConfigs/${urlCode}`)

        const { styling: customStyling, ...customConfig } = response.data

        styling = customStyling
        config = customConfig
      }

      setIsWebAppConfigLoading(true)
    } catch (err) {
      if (axios.isAxiosError(err) && (err as AxiosError)?.response?.status === 404) {
        // eslint-disable-next-line no-console
        console.warn('\n\nThe provided webappCode is not valid.\n\n')
      }
      setWebAppConfigError(err as Error)
    }

    window.parent.postMessage({ title: config.browserPageTitle, favicon: config.favicon }, '*')

    if (currentLanguage) {
      const isCurrentLanguageSupported = config.supportedLanguages.includes(currentLanguage)

      if (!isCurrentLanguageSupported) {
        window.appStorage.removeItem('xundWebAppLanguage')
      }
    }

    setWebAppConfig({ ...config, urlCode })
    initCssVariableConfig(styling, code)

    loadWebAppFonts(JS_STYLING.fontCssUrl)
    appendCustomizedStylesToDOM()

    document.title = config.browserPageTitle

    if (config.favicon) {
      const favicon = document.getElementById('favicon') as HTMLLinkElement
      favicon.href = config.favicon
    }

    setIsWebAppConfigLoading(false)
  }, [code, businessAdmin, token])

  /**
   * Load the fonts for the given Web App
   *
   * @param cssUrl The URL for the stylesheet to be loaded
   */
  const loadWebAppFonts = (cssUrl: string) => {
    const fontLink = document.createElement('link')
    fontLink.type = 'text/css'
    fontLink.rel = 'stylesheet'
    fontLink.href = cssUrl
    document.head.appendChild(fontLink)
  }

  useEffect(() => {
    getWebAppConfig()
  }, [getWebAppConfig])

  return { webAppConfig, isWebAppConfigLoading, webAppConfigError, isConfiguredWebapp }
}
