// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gTq1bwYA8bBu3lpzcn3w {\n  margin: 6px 10px;\n  position: relative;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n@media screen and (max-height: 576px) and (orientation: landscape) {\n  .gTq1bwYA8bBu3lpzcn3w {\n    margin-top: 70px;\n    height: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentLibrary/ContentLibrary.module.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AADF;AAIA;EACE;IACE,gBAAA;IACA,YAAA;EAFF;AACF","sourcesContent":["@import '../../variables.less';\n\n.container {\n  margin: 6px 10px;\n  position: relative;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n@media screen and (max-height: @mobile-max-height) and (orientation: landscape)  {\n  .container {\n    margin-top: 70px;\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "gTq1bwYA8bBu3lpzcn3w"
};
export default ___CSS_LOADER_EXPORT___;
