import styled from '@emotion/styled'

// TODO migrate MainPage.module.less to styled components

export const PageContainer = styled.div<{
  isContentLibraryOpen?: boolean
  isServicesOpen: boolean
  isOnStartScreen: boolean
  isReport: boolean
}>(
  `
  width: 100%;
  
  display: flex;
  flex-direction: column;
  &>.ant-row{
      flex-grow:1;
      align-content: flex-start;
    }

  `,
  (props) => props.isServicesOpen && `overflow: hidden;`,
  (props) => !props.isOnStartScreen && `margin-top: 50px;`,
  (props) =>
    props.isContentLibraryOpen
      ? `
    flex-grow:1;
    align-content: flex-start;
    `
      : `
    flex: 1;`,
  (props) =>
    !props.isReport &&
    `&>.ant-row .ant-col{
    min-height:100%
  }`,
)
