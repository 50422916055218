import { AxiosInstance } from 'axios'
import { OptionItem } from '../models/api/response/OptionItem'
import { Question } from '../models/Question'

/**
 * The method that filters the body parts
 *
 * @param optionsSetter The options setter method
 * @param errorSetter The error setter method
 * @param apiGateway The current axios instance
 * @param text The search text
 */
export const useSearch = async (
  optionsSetter: (param: OptionItem[]) => void,
  errorSetter: (param: Error) => void,
  apiGateway: AxiosInstance,
  text?: string,
) => {
  try {
    const { data } = await apiGateway.get<Question>(`/v1/chatbot/question?optionSearchTerm=${text || ''}`)
    optionsSetter(data.options?.values || [])
  } catch (err) {
    errorSetter(err as Error)
  }
}
