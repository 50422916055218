import React, { FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useDebouncedState } from 'xund-react-utils'
import { UnifiedMedicalItem, UnifiedMedicalItemResponse } from 'medical-engine-api'
import { Col, Row } from 'antd'

import { useApiGatewayContext } from '../../context'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { SearchInput } from '../General/SearchInput/SearchInput'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../constants'

import { ContentLibrarySelector } from './ContentLibrarySelector/ContentLibrarySelector'
import { ContentLibraryListItemDetails } from './ContentLibraryListItemDetails/ContentLibraryListItemDetails'
import styles from './ContentLibrary.module.less'
import { isAxiosError } from 'axios'

import { ScrollingListContainer } from '../../common'

export interface LoadingType {
  frequent?: boolean
  infrequent?: boolean
}

/**
 * @param props The props object
 * @param props.contentLibraryItemId The id of the current item
 * @param props.onItemSelected The method handling selection
 * @returns The ContentLibrary component
 */
export const ContentLibrary: FC<{
  contentLibraryItemId: string
  onItemSelected: (id: string) => void
}> = ({ contentLibraryItemId, onItemSelected }) => {
  const [frequentContentLibraryData, setFrequentContentLibraryData] = useState<UnifiedMedicalItem[]>([])
  const [infrequentContentLibraryData, setInfrequentContentLibraryData] = useState<UnifiedMedicalItem[]>([])
  const { setValue: setSearchText, debouncedValue: searchText } = useDebouncedState(
    '',
    DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS,
  )
  const [isLoading, setLoading] = useState<LoadingType>({})
  const [error, setError] = useState<Error>()

  const { apiGateway } = useApiGatewayContext()

  const abortControllerRef = useRef<AbortController>()
  /**
   * Fetches Content Library data
   */
  const getContentLibraryData = useCallback(
    async (
      setData: (value: SetStateAction<UnifiedMedicalItem[]>) => void,
      isFrequent: boolean | 'all' = 'all',
      searchTerm?: string,
    ) => {
      const loadingKey = isFrequent ? 'frequent' : 'infrequent'

      try {
        if (searchTerm && abortControllerRef.current) {
          abortControllerRef.current.abort()
        }
        abortControllerRef.current = new AbortController()

        if (isFrequent === 'all') {
          setLoading({ frequent: true, infrequent: true })
        } else {
          setLoading((prev) => ({ ...prev, ...{ [loadingKey]: true } }))
        }

        const { data } = await apiGateway.get<UnifiedMedicalItemResponse>(`v1/contentLibrary`, {
          signal: abortControllerRef.current.signal,
          params: { searchTerm, isFrequent: isFrequent === 'all' ? undefined : isFrequent },
        })

        setData(data.items)
      } catch (err) {
        if (isAxiosError(err) && err.code !== 'ERR_CANCELED') {
          setError(err as Error)
        }
      } finally {
        if (isFrequent === 'all') {
          setLoading({ frequent: false, infrequent: false })
        } else {
          setLoading((currentLoading) => ({ ...currentLoading, ...{ [loadingKey]: false } }))
        }
      }
    },
    [apiGateway],
  )

  /**
   * Decides which data to fetch based on search term
   */
  const fetchData = useCallback(
    (searchTerm: string) => {
      if (searchTerm) {
        return getContentLibraryData(setInfrequentContentLibraryData, 'all', searchTerm)
      }
      return Promise.all([
        getContentLibraryData(setFrequentContentLibraryData, true, searchTerm),
        getContentLibraryData(setInfrequentContentLibraryData, false, searchTerm),
      ])
    },
    [getContentLibraryData, setInfrequentContentLibraryData],
  )

  useEffect(() => {
    if (searchText) {
      fetchData(searchText)
    } else if (abortControllerRef.current) {
      setLoading({ frequent: false, infrequent: false })
      abortControllerRef.current.abort()
    }
  }, [fetchData, getContentLibraryData, searchText])

  useEffect(() => {
    if (
      contentLibraryItemId === '' &&
      frequentContentLibraryData.length === 0 &&
      infrequentContentLibraryData.length === 0
    ) {
      fetchData('')
    }
  }, [contentLibraryItemId, fetchData, frequentContentLibraryData.length, infrequentContentLibraryData.length])

  if (error) {
    throw error
  }

  return (
    <WrapperWithTitleAndAnimation style={{ justifyContent: 'unset', height: '100%' }} hasTopMargin={false}>
      {!contentLibraryItemId ? (
        <div className={styles.container}>
          <Row>
            <Col span={24}>
              <SearchInput
                setSearchText={setSearchText}
                isLoading={Number(isLoading.frequent || isLoading.infrequent)}
                style={{ marginBottom: 0 }}
              />
            </Col>
          </Row>
          <Row style={{ position: 'relative', flexGrow: 1 }}>
            <ScrollingListContainer style={{ width: '100%', position: 'absolute', height: '100%' }}>
              <ContentLibrarySelector
                frequentOptions={frequentContentLibraryData}
                infrequentOptions={infrequentContentLibraryData}
                searchText={searchText}
                isLoading={isLoading}
                onSelect={onItemSelected}
              />
            </ScrollingListContainer>
            {/* <Col style={{ width: '100%', position: 'absolute', height: '100%', overflow: 'scroll' }}>
            </Col> */}
          </Row>
        </div>
      ) : (
        <ContentLibraryListItemDetails id={contentLibraryItemId} />
      )}
    </WrapperWithTitleAndAnimation>
  )
}
