import { CheckPhaseStep } from '../models'

/**
 * Checks the detailed question type of the question
 * and sets chat progression accordingly
 *
 * @param state The state of checkStateInfo in the question response
 * @param stepCountSetter The setter for the step count
 */
export const changeCheckStatus = (state: string, stepCountSetter: (val: CheckPhaseStep) => void) => {
  const symptomStates = ['MAIN_SYMPTOM', 'SYMPTOM_SUGGESTIONS', 'PRIMARY_SYMPTOMS', 'SECONDARY_SYMPTOMS', 'OVERVIEW']
  const reportStates = ['FINISHED', 'DISCLAIMER', 'REPORTING']

  const startStep = 0
  const profileStep = 1
  const symptomsStep = 2
  const healthDataStep = 3
  const reportStep = 4

  if (state === 'START') {
    stepCountSetter(startStep)
  } else if (state === 'PROFILE') {
    stepCountSetter(profileStep)
  } else if (symptomStates.includes(state)) {
    stepCountSetter(symptomsStep)
  } else if (state === 'ILLNESS_SURVEY') {
    stepCountSetter(healthDataStep)
  } else if (reportStates.includes(state)) {
    stepCountSetter(reportStep)
  } else {
    stepCountSetter(startStep)
  }
}
