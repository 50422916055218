import addCircle from './add_circle.svg'
import address from './address.svg'
import arrowDown from './arrowDown.svg'
import arrowRight from './arrowRight.svg'
import book from './book.svg'
import cancel from './cancel.svg'
import changeLanguage from './changeLanguage.svg'
import cross from './cross.svg'
import crossInCircle from './crossInCircle.svg'
import doctor from './doctor.svg'
import doctorFilled from './doctor_filled.svg'
import download from './download.svg'
import edit from './edit.svg'
import email from './email.svg'
import emergency from './emergency.svg'
import envelope from './envelope.svg'
import filter from './filter.svg'
import home from './home.svg'
import hospital from './hospital.svg'
import hospitalCross from './hospitalCross.svg'
import hospitalFilled from './hospital_filled.svg'
import https from './https.svg'
import imprint from './imprint.svg'
import infoAdditionalSymptoms from './infoAdditionalSymptoms.svg'
import infoIllnessRelatedQuestions from './infoIllnessRelatedQuestions.svg'
import infoMainSymptomSelection from './infoMainSymptomSelection.svg'
import location from './location.svg'
import locationPin from './locationPin.svg'
import navigationArrow from './navigationArrow.svg'
import menu from './menu.svg'
import menuV2 from './menu_v2.svg'
import paragraph from './paragraph.svg'
import pharmacy from './pharmacy.svg'
import pharmacyFilled from './pharmacy_filled.svg'
import phone from './phone.svg'
import questionMark from './questionMark.svg'
import restart from './restart.svg'
import rotate from './rotate.svg'
import service from './service.svg'
import servicesSos from './services_sos.svg'
import star from './star.svg'
import switchOnOff from './switchOnOff.svg'
import thieme from './thieme.svg'
import tick from './tick.svg'
import tickInCircle from './tickInCircle.svg'
import warning from './warning.svg'
import world from './world.svg'
import zoom from './zoom.svg'
import healthSolutions from './healthSolutions.svg'
import stroke from './stroke.svg'
import showMore from './showMore.svg'
import close from './close.svg'
import check from './check.svg'
import mail from './mail.svg'
import navigation from './navigation.svg'
import horizontalFilter from './horizontal_filter.svg'
import icIcon from './illness-check_blue.svg'
import scIcon from './symptom-check_blue.svg'
import hcIcon from './health_check.svg'
import arrowUpperRight from './arrow_upper_right.svg'
import infoIcon from './info_icon.svg'
import examination from './examinations_icon.svg'
import familyHistory from './family_history.svg'
import age from './age.svg'
import sex from './sex.svg'
import height from './height.svg'
import weight from './weight.svg'

export const icons = {
  addCircle,
  address,
  arrowDown,
  arrowRight,
  arrowUpperRight,
  book,
  cancel,
  changeLanguage,
  cross,
  crossInCircle,
  doctor,
  doctorFilled,
  download,
  edit,
  email,
  emergency,
  envelope,
  filter,
  home,
  hospital,
  hospitalCross,
  hospitalFilled,
  https,
  imprint,
  infoAdditionalSymptoms,
  infoIllnessRelatedQuestions,
  infoMainSymptomSelection,
  location,
  locationPin,
  navigationArrow,
  menu,
  menuV2,
  paragraph,
  pharmacy,
  pharmacyFilled,
  phone,
  questionMark,
  restart,
  rotate,
  service,
  servicesSos,
  star,
  switchOnOff,
  thieme,
  tick,
  tickInCircle,
  warning,
  world,
  zoom,
  healthSolutions,
  stroke,
  showMore,
  close,
  check,
  mail,
  navigation,
  horizontalFilter,
  icIcon,
  scIcon,
  hcIcon,
  infoIcon,
  examination,
  age,
  sex,
  height,
  weight,
  familyHistory,
}

export type WebAppIcon = keyof typeof icons
