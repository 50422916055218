import React from 'react'
import { GenericSpecializationType } from 'medical-engine-api'
import { CustomIcon } from '../components/CustomIcon/CustomIcon'
import { IconContainer } from '../components/Services/ServicesMenu/ServiceDetails/ServiceDetails.styled'
import { CSS_VARS } from '../resources/cssVariableConfig'

/**
 * @returns Methods for getting service type icon properties
 */
export const useServiceIconProperties = () => {
  /**
   * @param serviceType The type of the service
   * @returns The appropriate icon for the service
   */
  const getServiceIcon = (serviceType: GenericSpecializationType) => {
    const iconColor = getServiceTypeColor(serviceType)
    const iconSize = 14

    const icon = serviceType.toLowerCase()

    return (
      <IconContainer>
        <CustomIcon
          icon={icon}
          style={{
            fill: iconColor,
            stroke: iconColor,
            height: iconSize,
            width: iconSize,
          }}
        />
      </IconContainer>
    )
  }

  /**
   * @param serviceType The service type
   * @returns The appropriate color for the service type
   */
  const getServiceTypeColor = (serviceType: GenericSpecializationType) =>
    ({
      DOCTOR: CSS_VARS.PRIMARY_COLOR,
      PHARMACY: '#00adef',
      HOSPITAL: CSS_VARS.RED_COLOR,
    }[serviceType])

  return { getServiceIcon, getServiceTypeColor }
}
