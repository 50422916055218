import React, { FC, ReactNode, useMemo, useState } from 'react'
import { UnifiedMedicalItem } from 'medical-engine-api'
import { useI18n, useBreakpoints } from '../../../hooks'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { Link, ListItem } from './ContentLibraryListItem.styled'
import { Highlight as HighlightCommon } from '../../../common'

/**
 * @param props The props object
 * @param props.item The current list item
 * @param props.onSelect The method handling selection (unused if openableInPlace is true)
 * @param props.elementReference The reference object
 * @param props.lastItem Indicates if this item is the last in the list
 * @param props.openableInPlace Indicates if the description is openable in place
 * @returns The ContentLibraryListItem component
 */
export const ContentLibraryListItem: FC<{
  item: UnifiedMedicalItem
  onSelect?: (val: string) => void
  elementReference?: (node: HTMLDivElement | null) => void
  lastItem?: boolean
  searchText?: string
  openableInPlace?: boolean
}> = ({ item, onSelect, searchText, elementReference, lastItem = false, openableInPlace = false }) => {
  const { i18n } = useI18n()

  const { isScreenSizeXS } = useBreakpoints()
  const computedIllnessListItemDescriptionLength = useMemo(() => (isScreenSizeXS ? 60 : 200), [isScreenSizeXS])

  const [isOpened, setOpened] = useState(false)

  const Highlight = (props: { children: ReactNode }) => {
    return <HighlightCommon term={searchText}>{props.children}</HighlightCommon>
  }

  return (
    <>
      <ListItem ref={elementReference} style={{ fontFamily: CSS_VARS.fontFamily }} isLast={lastItem}>
        <h3 style={{ fontSize: CSS_VARS.fontSize }}>
          <Highlight>{item.name}</Highlight>
        </h3>
        {item.type === 'ILLNESS' ? (
          <p style={{ fontSize: CSS_VARS.fontSize }}>
            {!openableInPlace && onSelect ? (
              <>
                <Highlight>{item.description.slice(0, computedIllnessListItemDescriptionLength)} ... </Highlight>
                <Link onClick={() => onSelect(item.id)}>{i18n('xund.general.showMore')}</Link>
              </>
            ) : (
              <>
                {isOpened ? (
                  <Highlight>{item.description}</Highlight>
                ) : (
                  <Highlight>{`${item.description.slice(0, computedIllnessListItemDescriptionLength)}... `}</Highlight>
                )}
                <Link onClick={() => setOpened(!isOpened)}>
                  {isOpened ? i18n('xund.general.showLess') : i18n('xund.general.showMore')}
                </Link>
              </>
            )}
          </p>
        ) : (
          <p style={{ fontSize: CSS_VARS.fontSize }}>
            <Highlight>{item.detailedDescription}</Highlight>
          </p>
        )}
      </ListItem>
    </>
  )
}
