import styled from '@emotion/styled'

import { CSS_VARS, JS_STYLING } from '../../resources/cssVariableConfig'

export const ScrollingListContainerStyled = styled.div<{
  isMobile: boolean
  isHidden: { bottom?: boolean; top?: boolean }
}>(
  `
  `,
  (props) => {
    const common = `
      :before,:after {
      content: '';
      position: absolute;
      width: 100%;
      z-index: 2;
      pointer-events: none;
      height: ${props.isMobile ? 40 : 50}px;
      opacity: 0;
      transition: opacity 0.6s ease;
    }
    :before {
      top: 0;  
      background: linear-gradient(to bottom, ${CSS_VARS.chatBackgroundColor}, rgba(255, 255, 255, 0));
    }
    :after {
      bottom: 0;  
      background: linear-gradient(to top, ${CSS_VARS.chatBackgroundColor}, rgba(255, 255, 255, 0));
    }`
    const maskTop = !props.isHidden.top
      ? `
      :before {
        opacity: 1;
      }`
      : ``
    const maskBottom = !props.isHidden.bottom
      ? `
      :after {
        opacity: 1;
        }`
      : ``
    return common + maskTop + maskBottom
  },
)

export const ScrollingList = styled.div(`

  margin: 0 -36px;
  padding: 0 36px;
  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    margin: 0 -23px;
    padding: 0 23px;
  }
  `)
