/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

// from https://github.com/capaj/localstorage-polyfill/blob/master/localStorage.js
const valuesMap = new Map()

class LocalStorage {
  getItem(key: string) {
    const stringKey = String(key)
    if (valuesMap.has(key)) {
      return String(valuesMap.get(stringKey))
    }
    return null
  }

  setItem(key: string, val: unknown) {
    valuesMap.set(String(key), String(val))
  }

  removeItem(key: string) {
    valuesMap.delete(key)
  }

  clear() {
    valuesMap.clear()
  }

  key(i: number) {
    if (arguments.length === 0) {
      throw new TypeError("Failed to execute 'key' on 'Storage': 1 argument required, but only 0 present.") // this is a TypeError implemented on Chrome, Firefox throws Not enough arguments to Storage.key.
    }
    const arr = Array.from(valuesMap.keys())
    return arr[i]
  }

  get length() {
    return valuesMap.size
  }
}

const instance: Storage = new LocalStorage()

const inMemoryLocalStorage = new Proxy(instance, {
  set(obj, prop: string, value: string) {
    if (LocalStorage.prototype.hasOwnProperty(prop)) {
      instance[prop] = value
    } else {
      instance.setItem(prop, value)
    }
    return true
  },
  get(target, name: string) {
    if (LocalStorage.prototype.hasOwnProperty(name)) {
      return instance[name]
    }
    if (valuesMap.has(name)) {
      return instance.getItem(name)
    }
  },
})

export default inMemoryLocalStorage
