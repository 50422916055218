import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { CSS_VARS } from '../../resources/cssVariableConfig'

const animationVertical = keyframes`
  0%, 50%, 100% {transform: translateY(0)}
  25% {transform: translateY(-20px)}
  75% {transform: translateY(20px)}  
`
const animationHorizontalLeft = keyframes`
  0%, 100% {transform: translateX(0)}
  50% {transform: translateX(-14px)}
`
const animationHorizontalRight = keyframes`
  0%, 100% {transform: translateX(0)}
  50% {transform: translateX(14px)}
`

export const LoadingIndicatorStyled = styled.div<{ color: string; inline: boolean; fullscreen: boolean }>`
  width: 100%;
  height: ${(props) => (props.inline || !props.fullscreen ? 'auto' : '100vh')};
  z-index: 1000;
  position: ${(props) => (props.inline ? 'static' : props.fullscreen ? 'fixed' : 'absolute')};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.inline &&
    `
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.3);

    transition: backdrop-filter 0.4s ease-in, background-color 0.4s ease-in;
    `};

  & svg circle {
    animation-duration: 0.9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }
  & svg circle.circle-h-l {
    animation-name: ${animationHorizontalLeft};
  }
  & svg circle.circle-h-r {
    animation-name: ${animationHorizontalRight};
  }
  & svg circle.circle-v {
    fill: ${(props) => (props.color === 'primary' ? CSS_VARS.primaryColor : CSS_VARS.outlinedButtonBackgroundColor)};
    animation-name: ${animationVertical};
    animation-duration: 1.8s;
  }
`
