import axios from 'axios'
import { useEffect, useMemo } from 'react'

/**
 * The Business Admin axios instance factory
 *
 * @param token The token to be used for the Business Admin
 * @returns The axios instance to be used for the Business Admin
 */
export const useBusinessAdmin = (token: string | null) => {
  /**
   * @returns An axios instance
   */
  const businessAdmin = useMemo(() => {
    return axios.create({
      baseURL: window.xundEnvironment.PP_INTERNAL_API_BASE_URL,
      timeout: 60000,
    })
  }, [])

  useEffect(() => {
    const interceptorId = businessAdmin.interceptors.request.use(
      async (config) => {
        config.headers.setAuthorization(`Bearer ${token}`)
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    return () => {
      businessAdmin.interceptors.request.eject(interceptorId)
    }
  }, [businessAdmin.interceptors.request, token])

  return { businessAdmin }
}
