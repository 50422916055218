import React from 'react'
import { LoaderLayer } from '../LoaderLayer'
import { MainPage } from './MainPage/MainPage'

/**
 * The application screen component
 *
 * @returns The application screen component
 */
export const AppScreen = () => (
  <LoaderLayer>
    <MainPage />
  </LoaderLayer>
)
