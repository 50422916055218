import axios from 'axios'
import { MAPBOX_MAP_BASE_URL } from '../constants'
import { isSupportedCountry } from '../utils/services/isSupportedCountry'

/**
 * @returns The method that converts coordinates into a country code
 */
export const useGetCountryCodeFromCurrentCoordinates = () => {
  /**
   * Gets and sets the country code for the current coordinates
   *
   * @param val The value object
   * @param val.longitude The current longitude
   * @param val.latitude The current latitude
   * @param setCountry The country setter
   * @param setLastSupportedCountryVisited The last supported country visited setter
   * @param setError The error setter
   */
  const getCountryCodeFromCurrentCoordinates = async (
    val: { longitude: number; latitude: number },
    setCountry: (value: string) => void,
    setLastSupportedCountryVisited: (value: string) => void,
    setError: (value: Error) => void,
  ) => {
    try {
      const { longitude, latitude } = val

      const { data } = await axios(`${MAPBOX_MAP_BASE_URL}/${longitude},${latitude}.json`, {
        params: {
          access_token: window.xundEnvironment.MAPBOX_ACCESS_TOKEN,
          types: 'country',
        },
      })

      if (data?.features?.length) {
        const currentCountry = data.features[0].properties.short_code.toUpperCase()
        setCountry(currentCountry)

        if (isSupportedCountry(currentCountry)) {
          setLastSupportedCountryVisited(currentCountry)
        }
      }
    } catch (err) {
      setError(err as Error)
    }
  }

  return { getCountryCodeFromCurrentCoordinates }
}
