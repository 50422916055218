import React, { ComponentPropsWithoutRef, ElementRef, ElementType, forwardRef } from 'react'

export interface ButtonProps {
  as?: ElementType
}

const DEFAULT_BUTTON_TAG: ElementType = 'button'

export const Button = forwardRef<
  ElementRef<typeof DEFAULT_BUTTON_TAG>,
  ComponentPropsWithoutRef<typeof DEFAULT_BUTTON_TAG>
>(({ children }, ref) => {
  return <button ref={ref}>{children}</button>
})
