import React, { FC } from 'react'
import { ClientQuickSolution } from 'medical-engine-api'
import { Heading } from '../../xund-ui-legacy-components'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { ModalText } from '../../Modals/ModalText/ModalText'
import { BaseModal } from '../../Modals/BaseModal/BaseModal'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { TransparencyRemoverContainer } from '../QuickSolutions.styled'

/**
 * @param props The props object
 * @param props.selectedQuickSolution The currently selected quick solution
 * @param props.setSelectedQuickSolution The setter for the currently selected quick solution
 * @returns The QuickSolutionsInfoModal component
 */
export const QuickSolutionsInfoModal: FC<{
  selectedQuickSolution: ClientQuickSolution
  setSelectedQuickSolution: (val: ClientQuickSolution | null) => void
}> = ({ selectedQuickSolution, setSelectedQuickSolution }) => {
  return (
    <BaseModal
      onCancel={() => setSelectedQuickSolution(null)}
      title={
        <>
          <TransparencyRemoverContainer>
            <CustomIcon
              icon={selectedQuickSolution?.iconUrl}
              color={CSS_VARS.textColor}
              style={{ width: 25, height: 25 }}
              colorizeByPlainCss
            />
          </TransparencyRemoverContainer>

          <Heading as={'h4'} style={{ fontSize: CSS_VARS.modalTitleFontSize, color: CSS_VARS.textColor }}>
            {selectedQuickSolution?.name}
          </Heading>
        </>
      }
      hasButtons={false}
    >
      <ModalText title={selectedQuickSolution?.description} />
    </BaseModal>
  )
}
