import React, { ComponentPropsWithoutRef, ElementRef, ElementType, forwardRef } from 'react'

import { StyledBadge } from './Badge.styled'

const DEFAULT_BADGE_TAG: ElementType = 'div'

export const Badge = forwardRef<
  ElementRef<typeof DEFAULT_BADGE_TAG>,
  ComponentPropsWithoutRef<typeof DEFAULT_BADGE_TAG>
>(({ children }, ref) => {
  return <StyledBadge ref={ref}>{children}</StyledBadge>
})
