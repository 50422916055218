import axios, { AxiosInstance } from 'axios'
import { createContext, useContext } from 'react'

const initialValue: {
  apiGateway: AxiosInstance
  medicalServices: AxiosInstance
  checkId: string
  setCheckId: (val: string) => void
  initialSymptom: string
  setInitialSymptom: (code: string) => void
  initialIllness: string
  setInitialIllness: (code: string) => void
} = {
  apiGateway: axios.create(),
  medicalServices: axios.create(),
  checkId: '',
  setCheckId: () => '',
  initialSymptom: '',
  setInitialSymptom: () => '',
  initialIllness: '',
  setInitialIllness: () => '',
}

export const ApiGatewayContext = createContext(initialValue)

/**
 * The custom hook for the API Gateway context
 *
 * @returns The custom hook for the API Gateway context
 */
export const useApiGatewayContext = () => useContext(ApiGatewayContext)
