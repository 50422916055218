import { Gender } from '../../context'
import { WebAppConfig } from '../../models'

type PatientPayload =
  | {
      resourceType: 'Patient'
      gender: Gender
      birthDate?: string
    }
  | {
      resourceType: 'Patient'
      gender?: Gender
      birthDate: string
    }

export type Payload =
  | {
      patient?: PatientPayload
      observations?: Array<{
        resourceType: 'Observation'
        code: {
          coding: Array<{
            code: string
          }>
        }
      }>
    }
  | {
      patient?: PatientPayload
      condition?: {
        resourceType: 'Condition'
        subject: {
          type: 'Patient'
        }
        code: {
          coding: Array<{
            code: string
          }>
        }
      }
    }

/**
 * calculates which context to use
 *
 * @param testConfig the config coming from props
 * @param realConfig the config coming from the hook
 * @returns The main page component
 */
export const actualWebAppConfig = (testConfig: WebAppConfig | undefined, realConfig: WebAppConfig) => {
  return testConfig ? testConfig : realConfig
}

/**
 * Get the payload for initialize symptom check
 *
 * @param code The code of the initial symptom
 * @returns Payload for initialize symptom check
 */
export const getInitializeSymptomCheckPayload = (code?: string): Payload => {
  return code
    ? {
        observations: [
          {
            resourceType: 'Observation',
            code: {
              coding: [
                {
                  code,
                },
              ],
            },
          },
        ],
      }
    : {}
}

/**
 * Get the payload for initialize illness check
 *
 * @param code The code of the initial illness
 * @returns Payload for initialize illness check
 */
export const getInitializeIllnessheckPayload = (code?: string): Payload => {
  return code
    ? {
        condition: {
          resourceType: 'Condition',
          subject: {
            type: 'Patient',
          },
          code: {
            coding: [
              {
                code,
              },
            ],
          },
        },
      }
    : {}
}

export class CheckFinishedError extends Error {
  constructor() {
    super()

    this.name = 'CheckFinishedError'
  }
}

export class CheckClosedError extends Error {
  constructor() {
    super()

    this.name = 'CheckClosedError'
  }
}
