import React, { FC, useMemo } from 'react'
import { useQueryParams } from 'xund-react-utils'

/**
 * Token component for the OAuth flow
 *
 * @returns The token component
 */
export const Token: FC = () => {
  const params = useQueryParams()
  const ppInternalApiBaseUrl = window.xundEnvironment.PP_INTERNAL_API_BASE_URL

  const { clientId, authCode, redirectUri } = useMemo(() => {
    return {
      clientId: params.get('clientId'),
      authCode: params.get('authCode'),
      redirectUri: params.get('redirectUri'),
    }
  }, [params])

  if (!clientId || !authCode) {
    throw new Error('Missing clientId or authCode')
  }

  window.appLocation.replace(
    `${ppInternalApiBaseUrl}/api/v1/token?clientId=${clientId}&authCode=${authCode}&redirectUri=${redirectUri}`,
  )

  return <></>
}
