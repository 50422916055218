import { useMemo, useState } from 'react'
import { useEventListener } from './useEventListener'

export const mobileMaxWidth = 576
const mobileMaxHeight = 576
const mobileLandscapeMaxWidth = 915

export const tabletMaxWidth = 820
const tabletMaxHeight = 1180

/**
 * @returns The current size of the screen, and booleans related to the screen size
 * @deprecated Use useWindowSize instead
 */
export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEventListener('resize', () =>
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    }),
  )

  const isLandscapeView = useMemo(() => screenSize.width > screenSize.height, [screenSize.height, screenSize.width])

  const isMobileView = useMemo(
    () =>
      screenSize.width <= mobileMaxWidth &&
      (screenSize.height < mobileMaxHeight || screenSize.height <= mobileLandscapeMaxWidth),
    [screenSize.height, screenSize.width],
  )

  const isMobileLandscapeView = useMemo(
    () => screenSize.width <= mobileLandscapeMaxWidth && screenSize.height <= mobileMaxHeight,
    [screenSize.height, screenSize.width],
  )

  const isTabletView = useMemo(
    () =>
      screenSize.width <= tabletMaxWidth && screenSize.width > mobileMaxWidth && screenSize.height > mobileMaxHeight,
    [screenSize.height, screenSize.width],
  )

  const isTabletLandscapeView = useMemo(
    () =>
      screenSize.width <= tabletMaxHeight &&
      screenSize.height <= tabletMaxWidth &&
      isLandscapeView &&
      !isMobileLandscapeView,
    [isLandscapeView, screenSize.height, screenSize.width, isMobileLandscapeView],
  )

  return { screenSize, isMobileView, isTabletView, isMobileLandscapeView, isLandscapeView, isTabletLandscapeView }
}
