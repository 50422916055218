import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AppScreen } from './components/AppScreen'
import { Authorize } from './components/OAuth/Authorize'
import { Token } from './components/OAuth/Token'

/**
 * The AppRoutes component
 *
 * @returns The AppRoutes component which has the current routes
 */
export const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppScreen />} />
        <Route path="/authorize" element={<Authorize />} />
        <Route path="/token" element={<Token />} />
        <Route path="/:code" element={<AppScreen />} />
      </Routes>
    </BrowserRouter>
  )
}
