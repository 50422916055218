import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { WebAppStyling, WebAppStylingFontStyle, WebAppStylingFontWeight } from '../models'
import { convertHexToRGBA } from '../utils/convertHexToRGBA'

interface WebAppStylingDict extends WebAppStyling {
  [key: string]: string | WebAppStylingFontStyle | WebAppStylingFontWeight | SizeType
}

const DIRECT_EXPOSE = [
  'fontCssUrl', // CSS_VARS ?
  'primaryColor',
]
export const JS_STYLING = {
  // exported as JS_STYLING but not as CSS_VARS
  MOBILE_MAX_WIDTH: '576px',
  MOBILE_MAX_HEIGHT: '576px',
  MOBILE_LANDSCAPE_MAX_WIDTH: '915px',
  TABLET_STANDARD_MAX_WIDTH: '768px',
  TABLET_MAX_WIDTH: '820px',
  TABLET_LARGE_MAX_WIDTH: '1368px',
} as unknown as WebAppStylingDict

const initCssVariableConfig = (styling: WebAppStyling, code?: string) => {
  const config = document.documentElement.style

  for (const [key, value] of Object.entries(styling)) {
    config.setProperty(`--${key}`, value) // creating CSS variables on the <HTML> node

    if (DIRECT_EXPOSE.includes(key)) {
      JS_STYLING[key] = value // exposing some styling values directly too to allow access to them in JS code
    }
  }

  // exported as CSS_VARS but not as JS_STYLING
  // TODO migrate into PAP-config based CSS variables
  config.setProperty(`--PRIMARY_COLOR`, '#0065BD')
  config.setProperty(`--GREEN_COLOR`, '#66D096')
  config.setProperty(`--RED_COLOR`, '#FF6666')
  config.setProperty(`--DARK_GREY_COLOR`, '#808080')
  config.setProperty(`--DARK_BLUE_COLOR`, '#00417A')

  // if not XUND then PAP-config based value else hardcoded XUND color
  config.setProperty(`--startScreenHeaderTextColor`, code ? 'var(--textColor)' : 'var(--DARK_BLUE_COLOR)')
  config.setProperty(`--lightTextColor`, code ? 'var(--textColor)' : 'var(--DARK_GREY_COLOR)')
  config.setProperty(`--darkTextColor`, code ? 'var(--textColor)' : 'black')

  config.setProperty(`--waveBgColor`, convertHexToRGBA(JS_STYLING.primaryColor, 0.1))
  config.setProperty(`--lightPrimaryColor`, convertHexToRGBA(JS_STYLING.primaryColor, 0.5))
}

// dictionary with possible CSS variable names
// TODO generate from code to get rid of manual maintain need
export const CSS_VARS = {
  primaryColor: `var(--primaryColor)`,
  textColor: `var(--textColor)`,
  successColor: `var(--successColor)`,
  fontSize: `var(--fontSize)`,
  fontStyle: `var(--fontStyle)`,
  fontWeight: `var(--fontWeight)`,
  buttonWeight: `var(--buttonWeight)`,
  buttonSize: `var(--buttonSize)`,

  navBarBackgroundColor: `var(--navBarBackgroundColor)`,
  navBarHamburgerIconColor: `var(--navBarHamburgerIconColor)`,

  sideMenuCloseIconColor: `var(--sideMenuCloseIconColor)`,
  sideMenuFontColor: `var(--sideMenuFontColor)`,

  chatBackgroundColor: `var(--chatBackgroundColor)`,

  questionFontColor: `var(--questionFontColor)`,
  questionFontSize: `var(--questionFontSize)`,

  outlinedButtonBackgroundColor: `var(--outlinedButtonBackgroundColor)`,
  outlinedButtonBorderColor: `var(--outlinedButtonBorderColor)`,
  outlinedButtonFontColor: `var(--outlinedButtonFontColor)`,

  filledButtonFontColor: `var(--filledButtonFontColor)`,

  skipButtonBackgroundColor: `var(--skipButtonBackgroundColor)`,
  skipButtonBorderColor: `var(--skipButtonBorderColor)`,
  skipButtonFontColor: `var(--skipButtonFontColor)`,

  confirmButtonFontColor: `var(--confirmButtonFontColor)`,

  modalTitleFontSize: `var(--modalTitleFontSize)`,

  inactiveElementColor: `var(--inactiveElementColor)`,

  bodyPartSelectorSVGBodyOutlineAndHoverColor: `var(--bodyPartSelectorSVGBodyOutlineAndHoverColor)`,
  bodyPartSelectorSVGColor: `var(--bodyPartSelectorSVGColor)`,

  reportProgressBackgroundColor: `var(--reportProgressBackgroundColor)`,

  progressBarActiveStepColor: 'var(--progressBarActiveStepColor)',
  progressBarInactiveStepColor: 'var(--progressBarInactiveStepColor)',
  progressBarProgressColor: 'var(--progressBarProgressColor)',
  progressBarProgressBackgroundColor: 'var(--progressBarProgressBackgroundColor)',

  fontFamily: `var(--fontFamily)`,

  PRIMARY_COLOR: `var(--PRIMARY_COLOR)`,
  LIGHT_PRIMARY_COLOR: `var(--LIGHT_PRIMARY_COLOR)`,
  GREEN_COLOR: `var(--GREEN_COLOR)`,
  RED_COLOR: `var(--RED_COLOR)`,
  DARK_GREY_COLOR: `var(--DARK_GREY_COLOR)`,
  DARK_BLUE_COLOR: `var(--DARK_BLUE_COLOR)`,

  startScreenHeaderTextColor: `var(--startScreenHeaderTextColor)`,
  lightTextColor: `var(--lightTextColor)`,
  darkTextColor: `var(--darkTextColor)`,

  waveBgColor: `var(--waveBgColor)`,
  lightPrimaryColor: `var(--lightPrimaryColor)`,
}

export default initCssVariableConfig
