import styled from '@emotion/styled'

export const StyledBadge = styled.div`
  display: inline-block;
  border: none;
  border-radius: 17.5px;
  background: #ebf3fa;
  line-height: 26px;
  margin-right: 5px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  transition: all 0.2s ease 0s, outline 0s ease 0s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 8px;
  gap: 6px;
  color: var(--questionFontColor);
  font-size: 14px;
`
