import React, { FC, useEffect, useState } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CSS_VARS } from '../../resources/cssVariableConfig'

interface Props {
  color?: 'primary' | 'secondary'
  delay?: Number
}

let timeout: ReturnType<typeof setTimeout>

export const LoadingIndicator: FC<Props> = (props) => {
  const { color = 'primary', delay } = props

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (delay && typeof delay === 'number') {
      timeout = setTimeout(() => {
        setShow(true)
      }, delay)
    } else {
      setShow(true)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  return (
    <div className="loadingIndicator">
      {show && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                color: color === 'primary' ? CSS_VARS.primaryColor : CSS_VARS.outlinedButtonBackgroundColor,
              }}
            />
          }
          style={{ width: '100%' }}
        />
      )}
    </div>
  )
}
