import React, { FC, ReactNode, useMemo, useState } from 'react'
import { ScrollingList, ScrollingListContainerStyled } from './ScrollingListContainer.styled'

import { mobileMaxWidth } from '../../hooks'
import { useWindowSize } from 'usehooks-ts'

export const ScrollingListContainer: FC<{
  children: ReactNode
  style?: React.CSSProperties
  className?: string
}> = ({ children, style, className = '' }) => {
  const borderWidth = 0.5
  const [atScrollStart, setAtScrollStart] = useState(true)
  const [atScrollEnd, setAtScrollEnd] = useState(false)

  const { width } = useWindowSize()
  const isMobile = useMemo(() => width < mobileMaxWidth, [width])

  return (
    <ScrollingListContainerStyled
      style={{ ...{ height: '100%' }, ...style }}
      className={className}
      isMobile={isMobile}
      isHidden={{ top: atScrollStart, bottom: atScrollEnd }}
    >
      <ScrollingList
        onScroll={(event) => {
          const target = event.target as HTMLElement
          setAtScrollStart(target.scrollTop <= borderWidth)
          setAtScrollEnd(target.offsetHeight + target.scrollTop + borderWidth >= target.scrollHeight)
        }}
        style={{ height: '100%', overflow: 'scroll' }}
      >
        {children}
      </ScrollingList>
    </ScrollingListContainerStyled>
  )
}
