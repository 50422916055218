import React, { ComponentProps, ComponentPropsWithoutRef, ElementType, forwardRef } from 'react'

const DEFAULT_TEXT_TAG: ElementType = 'div'

export interface TextProps<T extends ElementType> extends ComponentPropsWithoutRef<typeof DEFAULT_TEXT_TAG> {
  as: ElementType
  defaultTag: T
}

export const Text = forwardRef<
  React.ElementRef<ElementType>,
  TextProps<ElementType> & Omit<ComponentProps<ElementType>, 'css'>
>(({ children, as, defaultTag, componentRef, ...props }, ref) => {
  const Tag = as ?? 'div'
  return (
    <Tag ref={ref} {...props}>
      {children}
    </Tag>
  )
})
