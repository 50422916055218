import React, { FC, ReactNode, ComponentPropsWithoutRef, forwardRef } from 'react'
import styles from './BaseModal.module.less'
import {
  DialogRootContainer,
  DialogContentContainer,
  DialogActionsContainer,
  DialogTitleContainer,
} from './Dialog.styled'

export const DialogRoot: FC<{
  isOpen?: boolean
  id?: string
  open?: boolean
  onOpenChange?: (open: boolean) => void
  children?: ReactNode
}> = ({ isOpen, children }) => {
  return <DialogRootContainer>{children}</DialogRootContainer>
}

const DEFAULT_CONTENT_TAG = 'div'

export const DialogContent = forwardRef<
  React.ElementRef<typeof DEFAULT_CONTENT_TAG>,
  ComponentPropsWithoutRef<typeof DEFAULT_CONTENT_TAG>
>(({ children, ...props }, ref) => {
  return <DialogContentContainer id={styles.baseModal}>{children}</DialogContentContainer>
})

export const DialogActions: FC<{
  align: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch'
  justify: 'center' | 'space-between' | 'space-evenly' | 'flex-start' | 'flex-end'
  direction: 'column' | 'column-reverse' | 'row' | 'row-reverse'
  children?: ReactNode
}> = ({ children, align, justify, direction }) => {
  const css = {
    ...(align && { alignItems: align }),
    ...(justify && { justifyContent: justify }),
    ...(direction && { flexDirection: direction }),
  }
  return <DialogActionsContainer style={css}>{children}</DialogActionsContainer>
}

export const DialogTitle: FC<{
  id?: string
  children?: ReactNode
}> = ({ children }) => {
  return <DialogTitleContainer>{children}</DialogTitleContainer>
}

export const Dialog = {
  Root: DialogRoot,
  Content: DialogContent,
  Title: DialogTitle,
  Actions: DialogActions,
}
