import React, { FC } from 'react'

import { CloseOutlined } from '@ant-design/icons'

import { StyledCloseButton } from './CloseButton.styled'

interface Props {
  id?: string
  onClick?: () => void
}

export const CloseButton: FC<Props> = (props) => {
  return <StyledCloseButton {...props} icon={<CloseOutlined style={{ fontSize: '12px' }} />}></StyledCloseButton>
}
