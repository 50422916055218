import React, { FC, useCallback, useState } from 'react'
import { Select } from 'antd'
import { getLanguageNameByCode } from 'xund-i18n'
import { useLanguage, useI18n } from '../../../hooks'
import { SupportedLanguage } from '../../../models'
import { ModalText } from '../ModalText/ModalText'
import { BaseModal } from '../BaseModal/BaseModal'
import styled from '@emotion/styled'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const StyledSelect = styled(Select)`
  z-index: 1101;
  :hover,
  :focus {
    border-color: ${CSS_VARS.primaryColor};
  }
`

/**
 * @param props The props object
 * @param props.onCancel The method handling cancellation
 * @returns The LanguageSelectionModal component
 */
export const LanguageSelectionModal: FC<{
  onCancel: () => void
}> = ({ onCancel }) => {
  const { i18n } = useI18n()
  const { trackEvent } = useMatomo()

  const { currentLanguage, changeLanguage, supportedLanguages } = useLanguage()
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage)

  /**
   * The method handling language change
   *
   * @param language The language to change to
   */
  const handleLanguage = useCallback(
    (language: SupportedLanguage) => {
      changeLanguage(language)
      setSelectedLanguage(language)
      trackEvent({
        category: 'Side menu',
        action: 'Language changed',
        name: `${currentLanguage}->${language}`,
      })
      window.appLocation.reload()
    },
    [changeLanguage, currentLanguage, trackEvent],
  )

  return (
    <BaseModal
      title={i18n('xund.modal.languageSelection.title')}
      onCancel={onCancel}
      onOk={() => handleLanguage(selectedLanguage)}
      okBtnTitle={i18n('xund.general.restart')}
      okBtnDisabled={selectedLanguage === currentLanguage}
      cancelBtnTitle={i18n('xund.general.cancel')}
    >
      <StyledSelect
        id="languageInput"
        dropdownStyle={{ zIndex: 9999 }}
        placeholder={i18n('xund.modal.languageSelection.title')}
        style={{ paddingLeft: 0, width: '100%', textAlign: 'left', fontSize: CSS_VARS.fontSize }}
        size="large"
        onChange={(item) => {
          if (item !== null) {
            setSelectedLanguage(item as SupportedLanguage)
          }
        }}
      >
        {supportedLanguages.map((language) => {
          return (
            <Select.Option key={language} value={language}>
              {getLanguageNameByCode(language as SupportedLanguage)}
            </Select.Option>
          )
        })}
      </StyledSelect>
      <ModalText title={i18n('xund.general.restartWarning')} style={{ marginTop: '5px', textAlign: 'right' }} />
    </BaseModal>
  )
}
