// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NjqEIxqjLhm6PzxOOE8p {\n  width: 100%;\n  text-align: center;\n  margin: 40px auto 51px auto;\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentLibrary/ContentLibraryTitle/ContentLibraryTitle.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,2BAAA;EACA,gBAAA;AACJ","sourcesContent":[".title {\n    width: 100%;\n    text-align: center;\n    margin: 40px auto 51px auto;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "NjqEIxqjLhm6PzxOOE8p"
};
export default ___CSS_LOADER_EXPORT___;
