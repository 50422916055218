import React, { FC, useCallback, useEffect, useState } from 'react'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { useApiGatewayContext } from '../../../context'
import { useI18n, useLanguage } from '../../../hooks'
import { Imprint } from '../../../models'
import { LoadingIndicator } from '../../../common/Indicators/LoadingIndicator'
import { ModalText } from '../ModalText/ModalText'
import { BaseModal } from '../BaseModal/BaseModal'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './Impressum.module.less'
import { Typography } from 'antd'

const USER_MANUALS = {
  en: 'https://xund.ai/06-user-manual',
  de: 'https://xund.ai/06-benutzerhandbuch',
  'de-formal': 'https://xund.ai/06-benutzerhandbuch',
  fr: 'https://xund.ai/fr/user-manual',
  'fr-formal': 'https://xund.ai/fr/user-manual',
  hu: 'https://xund.ai/hu/user-manual',
  nl: 'https://xund.ai/nl/user-manual',
  'nl-formal': 'https://xund.ai/nl/user-manual',
  it: 'https://xund.ai/it/user-manual',
  'it-formal': 'https://xund.ai/it/user-manual',
}

let impressumDataCache: Imprint | null

/**
 * @param props The props object
 * @param props.onCancel The method handling cancellation
 * @returns The Impressum component
 */
export const Impressum: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const [impressumData, setImpressumData] = useState<Imprint | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const { i18n } = useI18n()
  const { apiGateway } = useApiGatewayContext()
  const { currentLanguage } = useLanguage()

  /**
   * Gets the data needed for the impressum
   */
  const getImpressumData = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get(`v1/imprintResources`)
      impressumDataCache = data
      setImpressumData(data)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway])

  useEffect(() => {
    if (!impressumDataCache) {
      getImpressumData()
    } else {
      setImpressumData(impressumDataCache)
    }
  }, [getImpressumData])

  if (error) {
    throw error
  }

  return (
    <BaseModal
      title={i18n('xund.imprint.title')}
      onCancel={onCancel}
      hasButtons={false}
      contentClassName={styles.impressum}
      isImpressumModal
    >
      {!isLoading ? (
        <>
          <div className={styles.impressumContainer}>
            <div>
              <h2 style={{ color: CSS_VARS.DARK_BLUE_COLOR, fontFamily: CSS_VARS.fontFamily }}>
                {i18n('xund.imprint.contact')}
              </h2>

              <div className={styles.impressumItemWithIcon}>
                <CustomIcon icon="https" className={styles.impressumIcon} />

                <a
                  href={impressumData?.impressum?.companyWebsite.path}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#1890ff', fontSize: CSS_VARS.fontSize }}
                >
                  {impressumData?.impressum?.companyWebsite.path}
                </a>
              </div>

              <div className={styles.impressumItemWithIcon}>
                <CustomIcon icon="envelope" className={styles.impressumIcon} />

                <ModalText title={impressumData?.impressum?.contactEmail.path} style={{ color: 'black' }} />
              </div>

              <div className={styles.impressumItemWithIcon}>
                <CustomIcon icon="phone" className={styles.impressumIcon} />

                <ModalText title={impressumData?.impressum?.bugReportEmail.path} style={{ color: 'black' }} />
              </div>

              <div className={styles.impressumItemWithIcon}>
                <CustomIcon icon="address" className={styles.impressumIcon} />

                <ModalText title={impressumData?.impressum?.contactAddress} style={{ color: 'black' }} />
              </div>
            </div>

            <hr></hr>

            <div className={styles.impressumInformation}>
              <h2 style={{ color: CSS_VARS.DARK_BLUE_COLOR, fontFamily: CSS_VARS.fontFamily }}>
                {i18n('xund.imprint.medicalProductInfo')}
              </h2>

              <div>
                <ModalText
                  title={`${i18n('xund.imprint.medicalProductInfo.product_name')}: ${
                    impressumData?.medicalProductInfo?.productName
                  }`}
                  style={{ color: 'black' }}
                />

                <ModalText
                  title={`${i18n('xund.imprint.medicalProductInfo.interface')}: ${
                    impressumData?.medicalProductInfo?.interface
                  }`}
                  style={{ color: 'black' }}
                />

                <ModalText
                  title={`${i18n('xund.imprint.medicalProductInfo.UDI_DI')}: ${
                    impressumData?.medicalProductInfo?.udiDi
                  }`}
                  style={{ color: 'black' }}
                />

                <ModalText
                  title={`${i18n('xund.imprint.medicalProductInfo.basic_UDI_DI')}: ${
                    impressumData?.medicalProductInfo?.basicUdiDi
                  }`}
                  style={{ color: 'black' }}
                />

                <ModalText
                  title={`${i18n('xund.imprint.medicalProductInfo.UDI_PI_date_of_manufacture')}: ${
                    impressumData?.medicalProductInfo?.udiPiDateOfManufacture
                  }`}
                  style={{ color: 'black' }}
                />

                <ModalText
                  title={`${i18n('xund.imprint.medicalProductInfo.UDI_PI_software_version')}: ${
                    impressumData?.medicalProductInfo?.udiPiSoftwareVersion
                  }`}
                  style={{ color: 'black' }}
                />

                <Typography>
                  <a
                    href={USER_MANUALS[currentLanguage]}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#1890ff', fontSize: CSS_VARS.fontSize }}
                  >
                    {i18n('xund.imprint.manual')}
                  </a>
                </Typography>
              </div>
            </div>
          </div>

          {impressumData?.medicalProductInfo.ceMark && (
            <img src={impressumData.medicalProductInfo.ceMark.path} className={styles.ceMark} alt="CE Mark" />
          )}
        </>
      ) : (
        <LoadingIndicator delay={200} inline />
      )}
    </BaseModal>
  )
}
