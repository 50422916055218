import { createContext, useContext } from 'react'
import { ErrorResponseType } from '../components/ErrorBoundary'
import { WebAppConfig } from '../models/WebAppConfig'
import { defaultWebAppConfig } from '../resources/defaultWebAppConfig'
import axios, { AxiosInstance } from 'axios'
import { PatientIntentType } from '../models'

const initialValue: {
  webAppConfig: WebAppConfig
  isWebAppConfigLoading: boolean
  webAppConfigError: Error | ErrorResponseType | null
  apiCHExternal?: AxiosInstance
  patientIntent?: PatientIntentType | null
  setPatientIntent?: (val: PatientIntentType | null) => void
} = {
  webAppConfig: defaultWebAppConfig,
  isWebAppConfigLoading: false,
  webAppConfigError: null,
  apiCHExternal: axios.create(),
  patientIntent: null,
  setPatientIntent: () => null,
}

export const WebAppConfigContext = createContext(initialValue)

/**
 * The custom hook for the Web App context
 *
 * @returns The custom hook for the Web App context
 */
export const useWebAppConfigContext = () => useContext(WebAppConfigContext)
