import React, { FC } from 'react'
import { useI18n } from '../../../hooks'
import { ModalText } from '../ModalText/ModalText'
import { BaseModal } from '../BaseModal/BaseModal'

/**
 * The home page modal component
 *
 * @param props The props object
 * @param props.onConfirm The method handling confirmation
 * @param props.onCancel The method handling cancellation
 * @returns The HomePageModal component
 */
export const HomePageModal: FC<{
  onConfirm: () => void
  onCancel: () => void
}> = ({ onConfirm, onCancel }) => {
  const { i18n } = useI18n()

  return (
    <BaseModal
      title={i18n('xund.modal.restart.title')}
      onCancel={onCancel}
      onOk={onConfirm}
      okBtnTitle={i18n('xund.general.restart')}
      cancelBtnTitle={i18n('xund.general.cancel')}
      contentStyle={{ paddingBottom: '50px' }}
    >
      <ModalText title={i18n('xund.general.checkWillBeLost')} />
    </BaseModal>
  )
}
