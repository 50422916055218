import React, { FC, useCallback } from 'react'
import classnames from 'classnames'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { useAppStateContext } from '../../../context'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './SideMenuToggle.module.less'
import { useMatomo } from '@datapunt/matomo-tracker-react'

/**
 * @returns The hamburger menu for the navbar
 */
export const SideMenuToggle: FC = () => {
  const { isSideMenuOpen, setSideMenuOpen } = useAppStateContext()
  const { trackEvent } = useMatomo()

  const handleClick = useCallback(() => {
    setSideMenuOpen(!isSideMenuOpen)
    trackEvent({
      category: 'Side menu',
      action: 'Menu opened',
    })
  }, [isSideMenuOpen, setSideMenuOpen, trackEvent])

  return (
    <div id="menu-btn" className={classnames(styles.SideMenuToggle)} onClick={handleClick}>
      <CustomIcon
        style={{
          color: CSS_VARS.navBarHamburgerIconColor,
        }}
        icon="menuV2"
        className={classnames(styles.icon)}
      />
    </div>
  )
}
