import React, { FC, useEffect, useState } from 'react'
import { LoadingIndicatorStyled } from './LoadingIndicator.styled'
import ReactDOM from 'react-dom'

interface Props {
  children?: React.ReactNode
  color?: 'primary' | 'secondary'
  delay?: number
  inline?: boolean
  fullscreen?: boolean
}

let timeout: ReturnType<typeof setTimeout>

export const LoadingIndicator: FC<Props> = (props) => {
  const { color = 'primary', delay, inline = false, fullscreen = true } = props

  const [enable, setEnable] = useState(false)

  const portalId = 'xund-indicators'
  let indicatorPortalTarget = document.getElementById(portalId)

  // using portal for backdrop effect

  if (!indicatorPortalTarget) {
    const root = document.getElementById('root')
    indicatorPortalTarget = document.createElement('div')
    indicatorPortalTarget.id = portalId
    if (!inline && fullscreen) {
      root?.appendChild(indicatorPortalTarget)
    }
  }

  useEffect(() => {
    if (delay && typeof delay === 'number' && delay > 0) {
      timeout = setTimeout(() => {
        setEnable(true)
      }, delay)
    } else {
      setEnable(true)
    }
    return () => {
      if (indicatorPortalTarget) {
        indicatorPortalTarget.remove()
        clearTimeout(timeout)
      }
    }
  }, [delay, indicatorPortalTarget])

  const indicator = (
    <LoadingIndicatorStyled color={color} inline={inline} fullscreen={fullscreen}>
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <circle cx="38" cy="24" r="4" fill="#D9D9D9" className="circle-h-l" />
        <circle cx="10" cy="24" r="4" fill="#D9D9D9" className="circle-h-r" />
        <circle cx="24" cy="24" r="4" fill="#0065BD" className="circle-v" />
      </svg>
    </LoadingIndicatorStyled>
  )

  return enable ? (
    inline || !fullscreen || !indicatorPortalTarget ? (
      indicator
    ) : (
      ReactDOM.createPortal(indicator, indicatorPortalTarget)
    )
  ) : (
    <></>
  )
}
