import { Grid } from 'antd'

/**
 * @returns Responsivity breakpoints
 */
export const useBreakpoints = () => {
  const { useBreakpoint } = Grid

  const isScreenSizeXS = useBreakpoint().xs

  return { isScreenSizeXS }
}
