import { Result, Typography } from 'antd'
import React, { Component, ErrorInfo, ReactNode } from 'react'
import { TokenExpiredRefresh } from './General/TokenExpiredRefresh/TokenExpiredRefresh'

export interface ErrorResponseType {
  message?: string
  response?: {
    data: {
      customType: string
      details?: {
        errorCode?: string
        description?: string
      }
      error: string
    }
    status: string
  }
}

export interface ErrorBoundaryState {
  error: ErrorResponseType
  hasError: boolean
}

/**
 * The ErrorBoundary class
 */
export class ErrorBoundary extends Component<
  {
    /**
     * The children object
     */
    children: ReactNode
  },
  ErrorBoundaryState
> {
  /**
   * The ErrorBoundary constructor
   *
   * @param props The props object
   * @param props.children The children object
   */
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = {
      error: this.state?.error,
      hasError: this.state?.error !== undefined,
    }
  }

  /**
   * The getDerivedStateFromError method
   *
   * @param error The error object
   * @returns The hasError boolean
   */
  public static getDerivedStateFromError(error: Error) {
    return { error, hasError: true }
  }

  /**
   * The componentDidCatch method
   *
   * @param error The error object
   * @param errorInfo The errorInfo object
   */
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error })
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  /**
   * The render method
   *
   * @returns The ErrorModal component if there is an error, otherwise props.children
   */
  public render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    if (this.state.error.response?.data?.customType === 'INVALID_TOKEN') {
      return <TokenExpiredRefresh />
    }

    if (this.state.error.response?.data?.customType || this.state.error.response?.data?.details?.errorCode) {
      return (
        <Result
          status="error"
          title="Error"
          subTitle={<Typography className="errorMessage">{this.state.error.response.data.error}</Typography>}
          extra={
            <>
              <Typography className="errorMessage">
                {this.state.error.response.data.customType
                  ? this.state.error.response.data.customType
                  : this.state.error.response.data.details?.errorCode}
              </Typography>
              <Typography className="errorMessage">{this.state.error.response.data.details?.description}</Typography>
            </>
          }
          style={{ marginTop: 200 }}
        />
      )
    }
    if (this.state.error.message) {
      return (
        <Result
          status="error"
          title="Error"
          subTitle={<Typography className="errorMessage">{this.state.error.message}</Typography>}
          style={{ marginTop: 200 }}
        />
      )
    }
    return this.props.children
  }
}
