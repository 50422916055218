import { useCallback, useEffect, useState } from 'react'
import { useApiGatewayContext } from '../context/ApiGatewayContext'

/**
 * @param url The url to fetch
 * @returns The response of the request, the isLoading boolean, and the potential error object
 */
export const useFetchData = <T>(url: string) => {
  const [fetchedData, setFetchedData] = useState<T | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const { medicalServices } = useApiGatewayContext()

  /**
   * Fetches data
   */
  const getData = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await medicalServices.get<T>(url)

      setFetchedData(data)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [medicalServices, url])

  useEffect(() => {
    getData()
  }, [getData])

  return {
    fetchedData,
    isLoading,
    error,
  }
}
