import { GenericSpecializationType, Gender } from 'medical-engine-api'

export const OpeningDays = ['0', '1', '2', '3', '4', '5', '6'] as const
export type OpeningDaysType = (typeof OpeningDays)[number]

export const AllSupportedCountries = ['AT', 'DE', 'HU'] as const
export type SupportedCountryCode = (typeof AllSupportedCountries)[number]

export type OpeningHours = { [K in number]: Array<{ start: string; finish: string }> }

export enum SpecializationType {
  Hospital = 'HOSPITAL',
  Pharmacy = 'PHARMACY',
  Doctor = 'DOCTOR',
}

export interface Service {
  id: string
  name: string
  monogram: string
  type: SpecializationType.Doctor
  gender: Gender
  openingHours?: OpeningHours
  phone: string
  email: string
  location: {
    province?: string
    country: SupportedCountryCode
    zip: string
    city: string
    address: string
    latitude: number
    longitude: number
  }
  profileImageUrl?: string
  specializations?: Specialization[]
  insurers?: string[]
  createdAt?: Date
  updatedAt?: Date
}

export interface Specialization {
  id: string
  name: string
  type?: GenericSpecializationType
}

export type LocalService = Omit<Service, 'monogram' | 'specializations'> & { specializations: string[] }

/**
 * Details of relative opening when type is OPENS_SOON
 */
type OpenSoonDetails = {
  type: 'OPENS_SOON'
  isOpen: false
  timeData: {
    time: string
  }
}

/**
 * Details of relative opening when type is CLOSES_SOON
 */
type ClosesSoonDetails = {
  type: 'CLOSES_SOON'
  isOpen: true
  timeData: {
    time: string
  }
}

/**
 * Details of relative opening when type is CLOSED, CLOSED_TODAY or ALREADY_CLOSED
 */
type ClosedDetails = {
  isOpen: false
  type: 'CLOSED' | 'CLOSED_TODAY' | 'ALREADY_CLOSED'
}

/**
 * Details of relative opening when type is OPEN
 */
type OpenDetails = {
  type: 'OPEN'
  isOpen: true
}

/**
 * Details of relative opening when type is NO_DATA
 */
type NoDataDetails = {
  type: 'NO_DATA'
}

export type DetailedOpeningHours = Exclude<Service['openingHours'], undefined> & {
  detailedData: {
    text: string
  } & (OpenSoonDetails | ClosesSoonDetails | ClosedDetails | OpenDetails | NoDataDetails)
}

export type OpeningDataType = DetailedOpeningHours['detailedData']['type']

export type ServiceWithDetailedOpeningHours = Omit<Service, 'openingHours'> & {
  openingHours: DetailedOpeningHours
}
