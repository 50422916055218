import React from 'react'
import { Typography } from 'antd'
import { WifiOutlined } from '@ant-design/icons'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { useI18n } from '../../hooks'

/**
 * @returns The NoConnection component
 */
export const NoConnection = () => {
  const { i18n } = useI18n()

  return (
    <WrapperWithTitleAndAnimation title={i18n('xund.general.noConnectionTitle')} style={{ width: 'fit-content' }}>
      <Typography.Title level={5}>
        <WifiOutlined /> {i18n('xund.general.noConnectionDescription')}
      </Typography.Title>
    </WrapperWithTitleAndAnimation>
  )
}
