import React, { FC, CSSProperties, useState, useCallback, useEffect } from 'react'
import SVG from 'react-inlinesvg'
import { icons, WebAppIcon } from '../../assets/icons/icons'
import { useCMS } from '../../hooks'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { ColorizerContainer } from './CustomIcon.styled'

/**
 * @param props The props object
 * @param props.icon The icon to be used
 * @param props.className The class name to be used
 * @param props.style The style to be used
 * @param props.color The color to be used
 * @param props.title The title of the svg, shown on hover
 * @returns The CustomIcon component
 */
export const CustomIcon: FC<{
  icon: WebAppIcon | string
  className?: string
  style?: CSSProperties
  color?: string
  title?: string
  colorizeByPlainCss?: boolean
}> = ({ icon, className, style, color, title, colorizeByPlainCss }) => {
  const [svgIcon, setSvgIcon] = useState('')

  const cmsInstance = useCMS()

  /**
   * Gets the SVG from the iconUrl
   *
   * @param url The icon URL
   * @returns Sets the SVG, or returns an error icon
   */
  const getIcon = useCallback(
    async (url: string) => {
      try {
        const { data } = await cmsInstance.get(url)

        setSvgIcon(URL.createObjectURL(data))
      } catch (err) {
        setSvgIcon(icons.warning)
      }
    },
    [cmsInstance],
  )

  useEffect(() => {
    if (!Object.keys(icons).includes(icon) && !svgIcon) {
      getIcon(icon)
    }
  }, [svgIcon, getIcon, icon])

  return colorizeByPlainCss && svgIcon !== icons.warning ? ( // warning icon doesn't look right when using colorizeByPlainCss
    <ColorizerContainer color={color}>
      <SVG
        title={title}
        src={icons[icon as WebAppIcon] || svgIcon}
        // TODO reconsider using className and style prop when using colorizeByPlainCss
        className={className}
        style={{ fill: CSS_VARS.questionFontColor, stroke: CSS_VARS.questionFontColor, ...style }}
      />
    </ColorizerContainer>
  ) : (
    <SVG
      title={title}
      src={icons[icon as WebAppIcon] || svgIcon}
      className={className}
      style={{ fill: CSS_VARS.questionFontColor, stroke: CSS_VARS.questionFontColor, ...style }}
      preProcessor={(code) => {
        let replaced = code

        if (color) {
          replaced = replaced.replace(/fill="((?!none).+)"/g, `fill="${color}"`)
          replaced = replaced.replace(/(stroke)="((?!none).+)"/g, `stroke="${color}"`)
        }

        return replaced
      }}
    />
  )
}
