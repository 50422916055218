import axios from 'axios'

/**
 * Checks whether the URL given as the backgroundImage property in the webAppConfig is available,
 * or sets the fallback image if the URL cannot be used
 *
 * @param imgUrl The url of the image
 */
export const determineBackgroundImageToUse = async (imgUrl?: string) => {
  const bgImage = document.getElementById('background_image')

  if (bgImage && imgUrl) {
    if (imgUrl.includes('#')) {
      bgImage.style.backgroundColor = imgUrl
    } else {
      try {
        await axios(imgUrl)
        bgImage.style.background = `url(${imgUrl}) no-repeat center center`
      } catch (e) {
        /* empty */
      }
    }
  }
}
