import React, { FC } from 'react'
import { useI18n } from '../../../hooks'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './ContentLibraryTitle.module.less'

/**
 * @returns Title for the content library
 */
export const ContentLibraryTitle: FC<{}> = () => {
  const { i18n } = useI18n()

  return (
    <h1
      className={styles.title}
      style={{
        fontFamily: CSS_VARS.fontFamily,
        color: CSS_VARS.questionFontColor,
        fontSize: CSS_VARS.modalTitleFontSize,
      }}
    >
      {i18n('xund.menu.medicalLibrary')}
    </h1>
  )
}
