// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LonyOUDJ1HRdLnnXfGK4 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-top: 0;\n  overflow: scroll;\n  padding-top: 20px;\n  padding-bottom: 30px;\n}\n.LonyOUDJ1HRdLnnXfGK4 h2 {\n  margin-bottom: 20px;\n  color: #808080;\n}\n.Zc701y2uFbXNC0L8Qbul {\n  margin-bottom: 0;\n}\n@media screen and (max-width: 820px) {\n  .LonyOUDJ1HRdLnnXfGK4 {\n    height: 55vh;\n  }\n}\n@media screen and (max-height: 576px) and (orientation: landscape) {\n  .LonyOUDJ1HRdLnnXfGK4 {\n    height: 65vh;\n  }\n}\n@media screen and (max-width: 576px) {\n  .LonyOUDJ1HRdLnnXfGK4 {\n    height: 75vh;\n  }\n  .Zc701y2uFbXNC0L8Qbul {\n    font-size: 15px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentLibrary/ContentLibrarySelector/ContentLibrarySelector.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EAEA,gBAAA;EACA,iBAAA;EACA,oBAAA;AAFF;AANA;EAWI,mBAAA;EACA,cAAA;AAFJ;AAMA;EACE,gBAAA;AAJF;AAOA;EACE;IACE,YAAA;EALF;AACF;AAQA;EACE;IACE,YAAA;EANF;AACF;AASA;EACE;IACE,YAAA;EAPF;EAUA;IACE,eAAA;EARF;AACF","sourcesContent":["@import '../../../variables.less';\n\n.contentLibrarySelectorContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-top: 0;\n  \n  overflow: scroll;\n  padding-top: 20px;\n  padding-bottom: 30px;\n\n  h2 {\n    margin-bottom: 20px;\n    color: #808080;\n  }\n}\n\n.listText {\n  margin-bottom: 0;\n}\n\n@media screen and (max-width: @tablet-max-width) {\n  .contentLibrarySelectorContainer {\n    height: 55vh;\n  }\n}\n\n@media screen and (max-height: @mobile-max-height) and (orientation: landscape) {\n  .contentLibrarySelectorContainer {\n    height: 65vh;\n  }\n}\n\n@media screen and (max-width: @mobile-max-width) {\n  .contentLibrarySelectorContainer {\n    height: 75vh;\n  }\n\n  .listText {\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentLibrarySelectorContainer": "LonyOUDJ1HRdLnnXfGK4",
	"listText": "Zc701y2uFbXNC0L8Qbul"
};
export default ___CSS_LOADER_EXPORT___;
