import styled from '@emotion/styled'
import { Button } from 'antd'

export const StyledCloseButton = styled(Button)<{ variant?: string }>`
  display: flex;
  position: absolute;
  top: 10px;
  right: 14px;

  color: rgb(128, 128, 128);
  background-color: transparent;
  border: 1px solid transparent;

  transition: all 0.2s ease 0s, outline 0s ease 0s;

  white-space: nowrap;
  outline: none;
  border-radius: 99999px;
  max-height: 40px;
  max-height: 40px;
  gap: 16px;
  font-size: 14px;
  cursor: pointer;

  padding: 0px;

  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`
