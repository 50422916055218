export const DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS = 300

const AGE_QUESTION_VALUES_RANGE = 100
export const AGE_QUESTION_MIN = new Date().getFullYear() - AGE_QUESTION_VALUES_RANGE
export const AGE_QUESTION_MAX = new Date().getFullYear()

const DEFAULT_USER_AGE = 30
export const DEFAULT_BIRTH_YEAR = new Date().getFullYear() - DEFAULT_USER_AGE

export const DEFAULT_TEMPERATURE = 38
export const DEFAULT_SCALE_VALUE = 5.5
export const TEMPERATURE_STEP_SIZE = 0.1
export const SCALE_STEP_SIZE = 0.5
export const SLIDER_MIN = 0
export const SLIDER_MAX = 100
