import styled from '@emotion/styled'

export const StyledCard = styled.div`
  background-color: theme.colors.background;
  display: 'block';
  border-radius: 10px;
  box-shadow: 0px 12px 35px rgba(0, 65, 122, 0.08);
  -webkit-box-shadow: 0px 12px 35px rgba(0, 65, 122, 0.08);
  -moz-box-shadow: 0px 12px 35px rgba(0, 65, 122, 0.08);
`
