import React, { CSSProperties, FC } from 'react'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { icons } from '../../../assets/icons/icons'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './MenuItem.module.less'

/**
 * @param props The props object
 * @param props.icon The icon to be used
 * @param props.title The title to be used
 * @param props.id The id to be used to support UI tests
 * @param props.style The custom styling to be used
 * @param props.onClick The method handling clicking
 * @param props.active Indicates the active state of the menu item
 * @returns The MenuItem component
 */
export const MenuItem: FC<{
  icon: keyof typeof icons
  title: string
  id: string
  style?: CSSProperties
  onClick: () => void
  active?: boolean
}> = ({ icon, title, id, style, onClick, active = false }) => {
  return (
    <div onClick={onClick} id={id} className={styles.Container} style={style}>
      <CustomIcon
        style={{
          fill: CSS_VARS.sideMenuFontColor,
          stroke: CSS_VARS.sideMenuFontColor,
          width: CSS_VARS.fontSize,
          height: CSS_VARS.fontSize,
          opacity: active ? 1 : 0.5,
        }}
        icon={icon}
        className={styles.icon}
      />
      <div
        className="menuItem"
        style={{
          color: CSS_VARS.sideMenuFontColor,
          fontSize: CSS_VARS.fontSize,
          fontWeight: active ? 'bold' : CSS_VARS.fontWeight,
          fontStyle: CSS_VARS.fontStyle,
          fontFamily: CSS_VARS.fontFamily,
        }}
      >
        {title}
      </div>
    </div>
  )
}
