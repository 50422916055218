import styled from '@emotion/styled'

export const ColorizerContainer = styled.div<{ color?: string }>(
  ``,
  (props) =>
    props.color &&
    `
    svg {
      // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/fill
      circle, ellipse, line, path, polygon, polyline, rect, text, textPath, tref, tspan {
        fill: ${props.color};
        stroke: ${props.color};
      }
    }
    `,
)
