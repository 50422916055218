import { useState } from 'react'

export interface ParentEventType {
  ui?: { keyboardIsVisible?: boolean; keyboardHeight?: number }
}

export const useParentEvents = () => {
  const [parentEvents, setParentEvents] = useState<ParentEventType>()

  const onResponse = (event: MessageEvent) => {
    if (!event.data) return

    if (event.data.xund) {
      setParentEvents(event.data.xund)
    }
  }

  window.addEventListener('message', onResponse, false)
  return { parentEvents }
}
